export const PatentabilitySearch = () => {
  return (
    <div style={{ background: "#00061a", padding: "0 50px" }}>
      <p
        style={{
          textAlign: "justify",
          fontSize: "20px",
          color: "#fff",
          marginTop: "0px",
        }}
      >
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
        > Patentability Search {" "}
        </span>
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Matic Research  {" "} </span>
        offers a customizable patentability Search service,
        designed to uncover whether your invention meets the criteria of novelty
        and non-obviousness when compared to existing prior art. The goal is to
        provide inventors with the confidence that their invention may qualify
        for a patent.
        <br />
        <br />
        <span>
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >  What makes Matic Research's Patentability Search Service the right choice for you…? {" "} </span>
          <br />
          
        </span>
        Our team will identify the distinctive features that your product brings
        to the market, empowering you to strategically capitalize on these
        innovative aspects to establish a strong market presence, build your
        brand, attract investments, and forge partnerships more effectively.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        ><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
         {" "} </span> We provides reverent result and that focus on the quality not on the quantity.  
          <br />

          <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
         {" "} </span> Our team successfully delivered thousands of projects to our satisfied clients.  
          <br />

          <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
        {" "} </span> We employ a combination of automated and manual analysis to
         thoroughly assess patent applications and competitor patents.  
          <br />

          <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
         {" "} </span> We offer multilingual support in over multiple languages, 
        catering to clients in non-English speaking countries, showcasing our global reach.  
          <br />

          <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
         {" "} </span> Furthermore, we provide a range of customize report options to accommodate our clients' 
        varying budgets and specific needs, ensuring a tailored approach to our services.  
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        > Our SOP (Standard Operating Procedure) for Patentability…? {" "} </span>
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >  Stage I: {" "} </span> We comprehensively understand and analyze the invention disclosure or product disclosure provided by the client and the construction of the key feature based on the provided
           invention disclosure or product disclosure or based on the requirements of the client.
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
           <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >  Stage II: {" "} </span> Keywords and their synonyms, and US, IPC, CPC, and other 
        classes are extracted based on invention disclosure or product information.
       <br /> 
       <br /> 
       <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
        <span  style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        > Stage III: {" "} </span> Important assignees and inventors are extracted and run based on the relevant prior art, and citation analysis is also performed on relevant prior art and 
        to identify subject matter, exhaustive searches are also performed on non-patent citations.
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
         <span  style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >Stage IV: {" "} </span> Prior art that is relevant to both patents and non-patents is color-coded and matched with essential characteristics for client convenience. 
        The client gets a customized report based on their instructions.
         <br /> 
         <br /> 
         <br />
          


      </p>
    </div>
  );
};
