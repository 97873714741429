import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import "./Projects.css";
import Header from "../Header/HeaderOne";
import portfolio1 from "../../assets/images/resource/portfolio-01.jpg";
import portfolio2 from "../../assets/images/resource/portfolio-02.jpg";
import portfolio3 from "../../assets/images/resource/portfolio-03.jpg";
import portfolio4 from "../../assets/images/resource/portfolio-04.jpg";
import portfolio5 from "../../assets/images/resource/portfolio-05.jpg";
import portfolio6 from "../../assets/images/resource/portfolio-06.jpg";
import portfolio7 from "../../assets/images/resource/portfolio-07.jpg";
import portfolio8 from "../../assets/images/resource/portfolio-08.jpg";

const Projects = () => {
  const data = [
    {
      id: 1,
      heading: "Electronic & Telecommunications",
      image: portfolio1,
      buttons: [
        "Telecom",
        "Robotics",
        "Fiber Optics",
        "Audio devices",
        "Microprocessor",
        "Microcontroller",
        "Instrumentation",
        "Signal Processing",
        "Image Processing",
        "Power Electronics",
        "Electronic Circuits",
        "Networking Devices",
        "Consumer Electronics",
        "Semiconductor Device",
        "Internet of Things (IOT)",
        "Radar",
        "Sonar",
        "Battery Charger",
        "Battery management",
        "Ultra-wide band",
        "NFC",
        "RFIC",
      ],
    },
    {
      id: 2,
      heading: "Electrical Engineering",
      image: portfolio2,
      buttons: [
        "Electrical Machines",
        "Transmission & Distributions",
        "Solar Panel",
        "Electric Vehicle",
        "Transformer",
        "Relay",
        "Switches",
        "AC motor",
        "Dc Motor",
        "Brushless DC Motor",
        "Fans",
        "Power Generator",
        "house Hold Appliances",
        "Circuit Breaker",
        "Safety device",
      ],
    },
    {
      id: 3,
      heading: "IT & Software Code",
      image: portfolio3,
      buttons: [
        "Big data",
        "E-commerce",
        "Web services",
        "Online services",
        "Search Engines",
        "Web and Media",
        "Firewall solution",
        "Neural Networks",
        "Cloud Computing",
        "Social Networking",
        "Payment gateway",
        "Financial software",
        "Virtual Reality (VR)",
        "Cloud Management",
        "Mobile Applications",
        "Artificial Intelligence",
        "Database management",
        "Augmented Reality (AR)",
        "Personalization Algorithms",
        "Blockchain/ Cryptocurrency",
      ],
    },
    {
      id: 4,
      heading: "Mechanical & Automobile",
      image: portfolio4,
      buttons: [
        "Aerospace",
        "Mechatronic",
        "Brake system",
        "Fluid Mechanics",
        "Thermal Engineering",
        "Fleet management",
        "automotive transportations & Power sports",
        "Tools & Equipments",
        "Suspension Component",
        "Engine & Engine Components",
        "Personal Motorized",
        "Recreation Vehicle",
        "Water pump",
        "General Engineering",
        "Door & Handle",
      ],
    },
    {
      id: 5,
      heading: "Medical Device & Procedure",
      image: portfolio5,
      buttons: [
        "Ablation Technology",
        "Oncology",
        "Cardiovascular",
        "Dental Device",
        "Diagnostics",
        "Ear",
        "Nose & Throat",
        "Gastrointestinal",
        "Hypertension management Devices",
        "Infusion Pump",
        "Neurology",
        "Orthopedics",
        "Dermatology",
        "Surgical Equipment",
        "Urology & Incontinence",
        "Biocompatible Polymers & coatings",
        "minimally invasive & Endoscopic Procedure",
      ],
    },
    {
      id: 6,
      heading: "Biotechnology & Pharmaceuticals",
      image: portfolio6,
      buttons: [
        "Aesthetic & Cosmetics Biotechnology",
        "Biofuels",
        "Vaccines",
        "Gronomics",
        "Biologics",
        "Diagnostics Tool",
        "Dosage & formulations",
        "Stem cell & Regenerative Medicine",
        "Synthetic Biology & Nanotechnology",
        "Botany",
        "hybrid plants",
        "Cross Genetics",
        "Biological Sequence Search",
        "therapeutics",
        "Tissue engineering",
        "Antibodies & Immunology",
        "Virus & Anti-virus",
      ],
    },
    {
      id: 7,
      heading: "Chemical Engineering",
      image: portfolio7,
      buttons: [
        "Biochemistry",
        "Combinational Chemistry",
        "Food Chemistry",
        "Fuel Cells & battery",
        "Mining Chemicals",
        "Organic Chemistry",
        "Inorganics Chemistry",
        "Physical Chemistry",
        "Pharmaceuticals Chemistry",
        "Oil",
        "Gas & Energy",
        "Analytics Chemistry",
        "Polymer Chemistry",
        "Cosmetics & personal care",
        "Paints Engineering",
        "Sealant",
        "Reaction Chamber",
        "Furnace",
        "Process control",
        "Fluid mechanics",
        "Chemical kinetics",
        "Catalysis",
        "Petrochemicals",
      ],
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Header />
        {data.map((item, index) => {
          return (
            <div key={item.id} className="section">
              <div
                className={`content ${
                  index % 2 === 0 ? "image-left" : "image-right"
                }`}
              >
                <img
                  src={item.image}
                  alt={`image-${item.id}`}
                  className={`image ${
                    index % 2 === 0 ? "slide-left" : "slide-right"
                  }`}
                />
                <div
                  className={`buttons-container ${
                    index % 2 === 0 ? "slide-right" : "slide-left"
                  }`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* Moved heading inside the buttons-container */}
                  <h1
                    className={index % 2 === 0 ? "slide-left" : "slide-right"}
                  >
                    {item.heading}
                  </h1>
                  <div
                    className={`subheading ${
                      index % 2 === 0 ? "slide-left" : "slide-right"
                    }`}
                  >
                    We cover almost all research areas, which include:
                  </div>
                  <div>
                    {item.buttons.map((btn, i) => {
                      return (
                        <button key={i} className="custom-button">
                          {btn}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <FooterOne />
    </>
  );
};

export default Projects;
