export const InventorWatch= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >  Inventor Watch </span>{" "} 
         <br /> 
         <br />
          Ensuring the continual market value of your patents demands vigilant monitoring. 
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research,
           </span>{" "} 
           our specialized expertise involves constant surveillance of inventors to thwart the registration of ideas mirroring yours. Trust us to oversee your intellectual property initiatives;
           our Inventor Watch Services are thorough, personalized, and crafted to maintain your competitive edge.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >
            What makes Matic Research a preferred choice for your inventor watch service…?
          </span>{" "}
          <br />
           Depending on your demands, <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > Matic Research 
           </span>{" "} may monitor intellectual property activities periodically, annually, or on a monthly basis.
          We provide a range of inventor watch services that are customized to your needs.
          <br />
        
          To keep you updated, our team carries out inventor searches, finding patents and non-patent material submitted or released by a certain inventor.
          <br />
        
          The purpose of the search is to ascertain whether the inventor is participating in any illicit activity or may be disclosing confidential information to their present employer from their former employer.
          <br />
          <br />
          <br />
        </p>
      </div>
    );
  };
  