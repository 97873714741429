export const PatentLitigationDescription = () => {
  return (
    <div
      style={{
        background: "#00061A",
        color: "#fff",
        marginTop: "0px",
        padding: "0 50px",
      }}
    >
      <br />
      <p
        style={{
          textAlign: "justify",
          margin: "0",
          fontSize: "20px",
        }}
      >
        {" "}
        Count on
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          {" "}
          Matic Research
        </span>{" "}
        as your trusted ally for patent litigation support. Our adept team of
        patent researchers is dedicated to resolving intricate disputes over
        patented inventions with precision and expertise. From initial case
        evaluation to strategic litigation planning, we offer thorough
        assistance to safeguard your intellectual property rights with
        confidence. Rely on us for steadfast and results-oriented representation
        in patent litigation matters.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}>
          What makes Matic Research the preferred choice for your Patent
          Litigation…?
        </span>{" "}
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          Matic Research
        </span>{" "}
        offers years of experience to defend our clients' rights in legal
        proceedings, regardless of whether they are the Plaintiffs or
        Defendants. Our litigation team is made up of knowledgeable intellectual
        property specialists with a wealth of expertise in supporting patent
        litigation and effectively handling challenging cases on behalf of our
        customers. Our experts work directly with you to assess a case's
        complexities and provide the best guidance possible.
        <br />
        <br />
      </p>
    </div>
  );
};
