import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/HomeOne/HomeOne/HomeOne";
import HomeTwo from "./Pages/HomeTwo/HomeTwo/HomeTwo";
import HomeThree from "./Pages/HomeThree/HomeThree/HomeThree";
import About from "./Pages/About/About";
import ScrollTop from "./Pages/ScrollTop/ScrollTop";
import Services from "./Pages/Services/Services";
import ServicesDetails from "./Pages/Services/ServicesDetails";
import IntellectualPropertyCommercialization from "./Pages/Services/IntellectualProp.Commercialization";
import MarketResearch from "./Pages/Services/MarketResearch";
import PatentLitigationSupport from "./Pages/Services/PatentLitigationSupport";
import PatentProsecutionSupport from "./Pages/Services/PatentProsecutionSupport";
import Projects from "./Pages/Projects/Projects";
import ProjectsDetails from "./Pages/Projects/ProjectsDetails";
import Pricing from "./Pages/Pricing/Pricing";
import Team from "./Pages/Team/Team";
import Testimonial from "./Pages/Testimonial/Testimonial";
import Error from "./Pages/Error/Error";
import Blog from "./Pages/Blog/Blog";
import BlogDetails from "./Pages/Blog/BlogDetails";
import Contact from "./Pages/Contact/Contact";
import WOW from "wowjs";
import AppRoutes from "./Pages/Routes/Routes";

function App() {
  useEffect(() => {
    new WOW.WOW({
      mobile: false,
    }).init();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home-two" element={<HomeTwo />} />
          <Route path="/Home-three" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-details" element={<ServicesDetails />} />
          <Route
            path="/IntellectualProp.Commercialization"
            element={<IntellectualPropertyCommercialization />}
          />
          <Route path="/MarketResearch" element={<MarketResearch />} />
          <Route
            path="/PatentLitigationSupport"
            element={<PatentLitigationSupport />}
          />
          <Route
            path="/PatentProsecutionSupport"
            element={<PatentProsecutionSupport />}
          />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects-details" element={<ProjectsDetails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/error" element={<Error />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/patent/*" element={<AppRoutes />} />
        </Routes>
      </Router>
      <ScrollTop />
    </>
  );
}

export default App;
