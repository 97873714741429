import { useState } from "react";
import { RDSupportHeading } from "./Components/RDSupportHeading";
import { RDSupportDescription } from "./Components/RDSupportDescription";
import { DataCollectionAndAnalysis } from "./Components/DataCollectionAndAnalysis";
import { IPmanagement } from "./Components/IPmanagement";
import { Prototypedevelopment } from "./Components/Prototypedevelopment";
import { Technicalwriting } from "./Components/Technicalwriting";
import { useParams } from "react-router-dom";
import FooterOne from "../../Footer/FooterOne";

export const RDSupport = () => {
  const subtopic = useParams();
  const value = subtopic[Object.keys(subtopic)[0]];
  const [, setSelectedComponent] = useState("RDSupportDescription");

  const renderComponent = () => {
    switch (value) {
      case "RDSupportDescription":
        return <RDSupportDescription />;
      case "Data Collection and Analysis":
        return <DataCollectionAndAnalysis />;
      case "Intellectual Property (IP) Management":
        return <IPmanagement />;
      case "Prototype Development":
        return <Prototypedevelopment />;
      case "Technical Writing":
        return <Technicalwriting />;
      default:
        return <RDSupportDescription />;
    }
  };
  return (
    <div>
      <RDSupportHeading setSelectedComponent={setSelectedComponent} />
      <div>{renderComponent()}</div>
      <FooterOne />
    </div>
  );
};
