import React, { useState } from "react";
import screenshot from "../../assets/ResearchDevelopment.jpg";
import {
  buttonStyle,
  containerStyle,
  containerHeadingStyle,
  containerParaStyle,
} from "../../Styles/styles";
import { useNavigate } from "react-router-dom";
import "./RDSupportHeading.css"; // Link to the CSS file for animations

export const RDSupportHeading = ({ setSelectedComponent }) => {
  const Navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonArr = [
    {
      name: "Data Collection and Analysis",
      component: "DataCollectionAndAnalysis",
      link: "Data Collection and Analysis",
    },
    {
      name: "Prototype Development",
      component: "Prototypedevelopment",
      link: "Prototype Development",
    },
    {
      name: "Technical Writing",
      component: "Technicalwriting",
      link: "Technical Writing",
    },
    {
      name: "Intellectual Property (IP) Management",
      component: "IPmanagement",
      link: "Intellectual Property (IP) Management",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        background: "#00061A",
        height: "100%",
        paddingLeft: "50px",
        paddingRight: "10px",
        marginBottom: "0px",
      }}
    >
      <div
        className="col-1 slide-left"
        style={{ width: "50%", paddingTop: "50px" }}
      >
        <h1
          style={{
            color: "#fff",
            fontSize: "55px",
            lineHeight: "1.2",
            marginBottom: "0px",
            textAlign: "left",
            background: "#00061A",
            marginTop: "0px",
          }}
        >
          Research & Development&nbsp;Support
        </h1>
        <p
          style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "20px",
          }}
        >
          We will help you to keep track of your Competitive analysis and
          Product/Services tracking.
          <br />
          <br />
        </p>
        {buttonArr.map((item, index) => (
          <button
            key={index}
            style={{
              border: "2px solid #ff0d76",
              color: hoveredButton === index ? "#ff0d76" : "#fff",
              background: "#00061a",
              borderRadius: "30px",
              borderWidth: "3px",
              margin: "10px",
              padding: "20px",
              cursor: "pointer",
              width: "auto",
              fontSize: "18px",
              fontWeight: "600",
            }}
            onMouseEnter={() => setHoveredButton(index)}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => {
              setSelectedComponent(item.component);
              if (item.link) Navigate(item.link); // Handle link absence
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div
        className="col-1 slide-right"
        style={{ width: "50%", paddingRight: "40px" }}
      >
        <img
          style={{
            height: "500px",
            width: "100%",
            marginTop: "50px",
          }}
          src={screenshot}
          alt="Research & Development Screenshot"
        />
      </div>
    </div>
  );
};
