import React, { useState } from "react";
import screenshot from "../../assets/Commercialization.jpg";
import {
  buttonStyle,
  containerStyle,
  containerHeadingStyle,
  containerParaStyle,
} from "../../Styles/styles";
import { useNavigate } from "react-router-dom";
import "./CommercializationSupport.css"; // import your new CSS file

export const CommercializationSupport = ({ setSelectedComponent }) => {
  const Navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonArr = [
    { name: "FTO Search", component: "FTOSearch", link: "FTO Search" },
    { name: "Evidence of Use", component: "EvOU", link: "Evidence of Use" },
    {
      name: "Inventor Watch",
      component: "InventorWatch",
      link: "Inventor Watch",
    },
    {
      name: "Licensing Support",
      component: "LicensingSupport",
      link: "Licensing Support",
    },
    {
      name: "Patent Monitoring",
      component: "PatMonitor",
      link: "Patent Monitoring",
    },
    {
      name: "Infringement Search",
      component: "InfringementSearch",
      link: "Infringement Search",
    },
    {
      name: "Patent Due Diligence",
      component: "PatentDueDiligence",
      link: "Patent Due Diligence",
    },
    {
      name: "Reverse Engineering",
      component: "ReverseEngineering",
      link: "Reverse Engineering",
    },
    {
      name: "Patent Portfolio Ranking",
      component: "PPortfolioRanking",
      link: "Patent Portfolio Ranking",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        background: "#00061A",
        height: "100%",
        paddingLeft: "50px",
        paddingRight: "10px",
        marginBottom: "0px",
      }}
    >
      <div
        className="col-1 slide-left"
        style={{ width: "50%", paddingTop: "50px" }}
      >
        <h1
          style={{
            color: "#fff",
            fontSize: "55px",
            lineHeight: "1.2",
            marginBottom: "0px",
            textAlign: "left",
            background: "#00061A",
            marginTop: "0px",
          }}
        >
          Intellectual Property Commercialization Support
        </h1>
        <p
          style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "20px",
          }}
        >
          Comprehensive patent prosecution support services for navigating the
          <br /> complexities of patent application processes.
        </p>
        {buttonArr.map((item, index) => (
          <button
            key={index}
            style={{
              border: "2px solid #ff0d76",
              color: hoveredButton === index ? "#ff0d76" : "#fff",
              background: "#00061a",
              borderRadius: "30px",
              borderWidth: "3px",
              margin: "10px",
              padding: "20px",
              cursor: "pointer",
              width: "auto",
              fontSize: "18px",
              fontWeight: "600",
            }}
            onMouseEnter={() => setHoveredButton(index)}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => {
              setSelectedComponent(item.component);
              if (item.link)
                Navigate(`/patent/commercialization-property/${item.link}`);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div
        className="col-1 slide-right"
        style={{ width: "50%", paddingRight: "40px" }}
      >
        <img
          style={{
            height: "500px",
            width: "100%",
            marginTop: "50px",
          }}
          src={screenshot}
          alt="Patent Prosecution Screenshot"
        />
      </div>
    </div>
  );
};
