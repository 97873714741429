export const CompetitiveWatchDescription = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        
         >
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research’s 
          </span>{" "}
          "Competitive Watch" service is a full suite of intellectual property services entailing the 
          systematic monitoring and analysis of competitors' intellectual property endeavors, strategies, 
          and advancements. It encompasses tracking competitors' patents, trademarks, copyrights, and other
           IP filings to glean insights into their innovation initiatives, market positioning, and potential
            risks or opportunities.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          > &#x21DB;  Key components of Competitive Watch include:
          </span>{" "}
          <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  &#x2794; 
           </span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           >  Monitoring Competitors' IP Filings: 
           </span>{" "} 
           <br />
           <span
           style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔
          </span>{" "}
          Consistently monitor competitors' patent applications, trademark registrations, and other IP
           submissions to discern emerging developments and trends within their intellectual property 
            portfolios.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  &#x2794; 
           </span>{" "} 

           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           >  Analyzing Competitors' IP Strategies: 
           </span>{" "} 
           <br />
           <span
           style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔
          </span>{" "}
           Delve into competitors' IP strategies to grasp their 
           areas of focus, strengths, and weaknesses in terms of intellectual property safeguarding and innovation.
          <br />
          <br />

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  &#x2794;  
           </span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           >  Identifying IP Trends and Innovations: 
           
           </span>{" "} 
           <br />
           <span
           style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔
          </span>{" "}
 Spot emerging IP trends, technologies, and innovations within competitors' portfolios to remain abreast 
           of industry advancements and potential avenues for collaboration or differentiation.
          <br />
          <br />

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  &#x2794;  
           </span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           >  Assessing Competitive Threats:
           </span>{" "} 

           <br />
           <span
           style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔
          </span>{" "}
  Evaluate competitors' IP holdings to pinpoint possible threats to your 
           own intellectual property rights and market standing, such as conflicting patents or trademarks.
          <br />
          <  br />
          
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  &#x2794; 
           </span>{" "} 

           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           >  Informing Business Strategy: 
           </span>{" "} 
           
           <br />
           <span
           style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔
          </span>{" "}
          Utilize insights from Competitive Watch to guide strategic decision-making, 
           including product development, marketing tactics, licensing opportunities, and defensive IP measures.
          <br />
          <  br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  &#x2794;
           </span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           >Detecting Infringement or Misuse:
           </span>{" "} 
           <br />
           <span
           style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔
          </span>{" "}
          Utilize insights from Competitive Watch to guide strategic decision-making, 
           including product development, marketing tactics, licensing opportunities, and defensive IP measures.
          <br />
          <br /> 
          Monitor competitors' IP undertakings to identify instances of infringement or misuse of your intellectual property rights, and take necessary enforcement actions as required.
          < br />
          In essence, Competitive Watch serves as an invaluable tool for 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  Matic Research, 
           </span>{" "} enabling businesses to stay apprised of competitors' intellectual property undertakings
            and leverage this intelligence to uphold a competitive edge in the market.

          <br />
          <br />
        </p>
      </div>
    );
  };
  