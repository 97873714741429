export const InfringementSearch= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >  Infringement Search</span>{" "} 
         <br /> 
         <br />
         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research, 
           </span>{" "} 
           we understand that patent violations can occur through various means, such as creating, using, proposing
           to sell, or employing a patented invention without the consent of the patent holder. Renowned for our 
           insightful analysis and expert guidance on intellectual property assets, we specialize in helping 
           businesses identify potential patent infringements and safeguard their interests. 
           Explore our Infringement Search service to fortify your patent protection strategy.
          <br />
          <br />
          Our highly skilled search and analytics team brings a wealth of topic knowledge to the table,
          enabling them to perform in-depth infringement studies. This guarantees a comprehensive analysis that doesn't miss anything, resulting in accurate and targeted results. Based on thorough investigations, we produce infringement reports and offer an extensive inventory of all items
         that are available that violate the lawless patents or portfolio of patents. 
         <br />
         <br />
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >  
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB;  </span>{" "}  Scale and Rating of Infringement: 
           </span>{" "} 
           <br />
           The Infringement Scale is the key component of our infringement evaluation approach. This unique scale, which goes from 0 to 10, measures how similar items are to the patent in question. Higher values on the scale indicate a larger possibility of infringement. 
           The numerical number on the scale refers to the level of probable infringement.
          <br />
          <br />

           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           > 
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB;  </span>{" "} Parameters for Scoring:
           </span>{" "} 
           <br />
           The elements thoughtfully considered in the scoring process include:
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}
           > Resemblance to Key Claim Elements:
           </span>{" "} 
            We carefully assess whether a product complies with the fundamental requirements listed in the patent's claims.
           <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}
           > Publicly Disclosed Information:
           </span>{" "}  Publicly accessible data about the product, like a datasheet or user manual,
            is taken into account in our evaluation.
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}
           > Revenue from the product market: 
           </span>{" "}  We take into account the product's financial performance.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}
           > Well-Informed Choice: 
           </span>{" "}  Our dedication extends beyond mere identification. Every product in our report includes a conclusive summary, offering guidance on whether to proceed or not. 

          <br />
          <br />
          Our studies in the reports offer a strategic perspective, highlighting crucial products and their associated companies related to potential infringements. This empowers you to make informed decisions and take the necessary actions to effectively protect your valuable intellectual property assets
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }} > &#x21DB; </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >  Here are types of patent Infringement:
           </span>{" "} 
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px", }}
           > Direct Infringement: 
           </span>{" "}   <br />
           This is the simple act of creating, utilizing, offering for sale, or utilizing the patented invention without permission, in clear violation of one or more patent claims.

           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           > Indirect Infringement:
           </span>{" "}  <br />
        Indirect Infringement involves providing parts for a product that violates intellectual property rights, or encouraging, inciting, or helping in another person's direct infringement.

           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           > Literal Infringement: 
           </span>{" "}  <br />
           Occurs when a procedure or product under scrutiny precisely fits the parameters of a legitimate, enforceable patent.

           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}
           > Doctrine of Equivalents: 
           </span>{" "}  <br />
           Even if a product or process does not literally infringe on a granted patent, it may still infringe if it is equivalent to the patented invention in terms of its function and purpose.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >   What makes Matic Research a preferred choice for your Patent Infringement Search…?
           </span>{" "}  
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}  Our team is experts in a comprehensive understanding of the patent. 
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}  A search is carried out, taking into account the patent's independent claims too.
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}  Carrying out an exhaustive search on one of the chosen claims.
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}  The product is analyzed by our subject-matter experts. 
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "} Our team offers Evidence of Use (EOU) or claim charts that link potentially infringing product qualities to key patent claim components.

           
          <br />
          <br />
        </p>
      </div>
    );
  };
  