export const PrePostGrantOpposition = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
    
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Pre/Post Grant Opposition</span>{" "} 
         <br /> 
         <br />

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >At Matic Research,  
          </span>{" "}
          we understand that the patent application process doesn't end with approval. As a patent
           holder/owner, it's crucial to remain vigilant against competitors and new market players who 
           might seek to patent similar intellectual property assets. You have the right, within a specified
            timeframe, to challenge an applicant's submission as the owner of the intellectual property.
             This requires the use of a patent watch, allowing continuous monitoring of industry technology
              trends and competitors' commercial activities. 

             <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research 
          </span>{" "} provides cost-effective Patent Opposition Services, showcasing our expertise in both Pre and Post Grant patent opposition capabilities.
          <br />
          <br />
          Our clients have greatly benefitted from our proactive monitoring of rival patents and our proficient
           filing of Pre- and Post-grant oppositions, backed by comprehensive searches. 
           Our legal experts and analysts possess extensive knowledge in both Pre-grant and Post-grant oppositions, coupled with exceptional search capabilities.
          <br />
          <br /> 
          Our Patent Opposition service is designed to strategically safeguard your patent's exclusivity against 
          competitors seeking to encroach upon your intellectual property, whether the opposition's patent is in
           the Pre-grant or Post-grant phase.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Why you Opt Matic Research for Pre and Post Grant Opposition…?
          </span>{" "}
          <br /> 
  
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;  </span>{" "} 
            An adept team comprising diverse professionals well-versed in Indian and global patent laws.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;  </span>{" "} 
          Comprehensive understanding of patent offices across different jurisdictions and their operational methodologies. 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;  </span>{" "}
          Swift and efficient delivery timelines.    
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;   </span>{" "}For best quality of result. 
         
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  