export const EvOU= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
          <br /> 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >  Evidence of Use </span>{" "} 
         <br /> 

          <br />
           Imagine having the power to confidently and accurately showcase the uniqueness and value of your
           intellectual property. 

           <br/>
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research,
           </span>{" "} 
           we're committed to turning that vision into reality. 
           By harnessing cutting-edge tools and expertise, we empower companies and inventors to discover, 
           compile, and assess the essential evidence needed to
           safeguard and uphold their intellectual property rights. Welcome to our Evidence of Use Service.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >
            At Matic Research,
          </span>{" "}
           our Evidence of Use chart is an essential tool in strategically aligning your claims with the relevant goods or services. This meticulously crafted chart serves as a vital guide, enabling you to substantiate the violation of your intellectual property rights or establish infringement with confidence. 
           Trust our Evidence of Use service to bolster your IP protection strategy effectively.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >
          Type of Evidence of Use (EOU)…?
          <br />
          </span>{" "}
          <span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span> Patent-to-Product Mapping
          <br /><span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span>  Patent-to-Standard Mapping
.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "}

          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          > {" "}
            Our method for crafting Evidence of Use (EOU) charts…?
          <br />
          </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >
          Matic Reseach
        
          </span>{" "}
          
           provides a dependable method for creating EOU charts. Our process involves a comprehensive assessment of the relevant patent, examining its prosecution history, scope, and specification first. Subsequently, we collect and centralize data on 
          selected products from diverse sources like teardown websites, product datasheets, and product brochures.
           <br />
          
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "}
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >
           What makes Matic Research a preferred choice for preparing your Evidence of Use Charts (EOU)…?
          < br/>
          </span>{" "}
          <span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span> Domain Experts: Crafting our EoU charts involves the insights of specialists 
          
          <br /><span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span>Exactness in Claim Interpretation: Our focus is on precise claim construction in EoU charts
        
          <br /><span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >✓ {" "} </span>Element Rule and Doctrine of Equivalents Integration: Our EoU charts 
          include vital legal principles like the Element Rule and Doctrine of Equivalents to bolster your case.
          <br />
          <br />
        </p>
      </div>
    );
  };
  