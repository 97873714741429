export const PatentDescription = () => {
  return (
    <div
      style={{
        background: "#00061A",
        color: "#fff",
        marginTop: "0px",
        padding: "0 50px",
      }}
    >
      <p
        style={{
          textAlign: "justify",
          margin: "0",
          fontSize: "20px",
        }}
      >
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          Matic Research:
        </span>{" "}
        offers comprehensive services to protect your intellectual property
        rights. Our tailored approach ensures that we understand your unique
        inventions and business goals, crafting personalized strategies for
        successful patent approval.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          Expertise and Experience:
        </span>{" "}
        Our team comprises seasoned patent attorneys and professionals with
        extensive experience in patent prosecution. We have successfully handled
        numerous cases across various industries, giving us a deep understanding
        of the nuances and complexities involved in obtaining patents.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          Tailored Approach:
        </span>{" "}
        We believe in a personalized and tailored approach to patent
        prosecution. We take the time to understand each client's unique
        inventions, goals, and business objectives. This allows us to craft
        customized strategies that maximize the chances of patent approval and
        provide comprehensive protection.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          {" "}
          Thorough Analysis:
        </span>{" "}
        Patent prosecution requires a thorough analysis of prior art, technical
        details, and legal considerations. We conduct exhaustive searches and
        evaluations to ensure that your invention is novel, non-obvious, and
        meets all patentability criteria. Our attention to detail sets us apart
        and strengthens your patent application.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          Effective Communication:
        </span>{" "}
        Communication is key in patent prosecution. We maintain open and
        transparent communication channels with our clients, keeping them
        informed at every stage of the process. Additionally, we engage in clear
        and effective dialogue with patent examiners to address any concerns or
        objections promptly.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          {" "}
          Strategic Planning:
        </span>{" "}
        Our strategic approach sets us apart from others. We not only focus on
        obtaining patents but also consider long-term strategies for patent
        portfolio management, enforcement, and commercialization. Our goal is to
        help clients maximize the value of their intellectual property assets.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          Results-Oriented:
        </span>{" "}
        Ultimately, our track record speaks for itself. We have a proven history
        of securing patents for our clients and helping them navigate the
        intricacies of patent prosecution successfully. Our commitment to
        delivering results and protecting our clients' innovations sets us apart
        as a trusted partner in intellectual property matters.
      </p>
      <br /> 
      <br />
    </div>
  );
};
