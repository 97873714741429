export const Prototypedevelopment = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
         <br /> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          > Prototype Development </span>{" "} 
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research </span>{" "}  assists researchers and developers in prototype development projects,
           where the focus is on creating an initial version of a product, system, or application.
            This allows for the evaluation of its viability, functionality, and design prior to full-scale 
            production. Our approach aims to validate concepts, gather feedback, identify potential issues, 
            and iteratively refine ideas to drive progress.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;  </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Key stages of a prototype development project at Matic Research include: </span>{" "} 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
          Conceptualization: </span>{" "} Defining objectives, requirements, and specifications
           based on intended use and target audience, often through brainstorming and market research sessions.
           <br />  
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Design: </span>{" "} Crafting the visual and functional aspects, such as UI/UX
          and interaction design, using wireframing, mockups, and prototyping tools for visualization.
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Development: </span>{" "} Constructing the prototype with suitable technologies and tools,
          including coding, programming, and integration of components or functionalities.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
          Testing and Evaluation: </span>{" "} Assessing performance, usability, and effectiveness
          through usability and functionality tests, incorporating user feedback to identify areas for enhancement.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
          Iteration and Refinement:  </span>{" "} Iteratively improving the prototype based on 
          feedback and testing results, refining design elements, adding features, or addressing usability concerns.
          < br/> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
          Documentation: </span>{" "} Documenting design decisions, development process, 
          and testing outcomes for transparency and future iterations, serving as a reference for stakeholders.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x2794; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
          Presentation and Demonstration: </span>{" "} Showcasing the prototype to stakeholders, clients, or 
          investors to demonstrate its features, value, and potential, facilitating further feedback and refinement.
          <br />
          < br /> 
          Ultimately, a prototype development project at  
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research
          </span>{" "}
          aims to create a tangible representation of a product or system for idea validation, feedback gathering,
          and decision-making support. Through a structured approach encompassing conceptualization, design, development, testing, iteration, and documentation, these projects help mitigate risks, lower costs, and enhance the chances of success in product or system development and implementation.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >How we perform Prototype development...?
          </span>{" "}
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "30px" }}>  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Conceptualization: 
         </span>{" "} 
         <br/>  
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Define the purpose and objectives of the prototype.
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Brainstorm ideas and concepts to address the problem or need.
        < br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "30px" }}>  &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Design:
         </span>{" "} 
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Create a detailed design plan based on the conceptualized idea.
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Develop sketches, wireframes, or mockups to visualize the prototype's structure and functionality.
        < br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "30px" }}>  &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Prototype Creation: 
         </span>{" "} 
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Build a basic version of the prototype using appropriate tools and materials.
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Focus on creating a functional representation of the product, even if it lacks 
                  the full features and polish of the final version
        < br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "30px" }}>  &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Testing and Feedback:
         </span>{" "} 
         <br/> 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
         Conduct thorough testing of the prototype to identify any flaws or areas for improvement.
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Gather feedback from stakeholders, users, or target audience to understand their experiences and preferences.
        < br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "30px" }}>  &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Iteration and Refinement: 
         </span>{" "} 
         <br/>  
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Analyze the feedback received and identify necessary changes or enhancements.
         <br/>  <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Iteratively refine the prototype based on user feedback and testing results
             to improve usability, functionality, and overall quality.
        < br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "30px" }}>  &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Validation:
         </span>{" "} 
         <br/>  
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Validate the prototype against the initial objectives and requirements to ensure it meets the intended goals.
         <br/> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Assess whether the prototype effectively addresses the problem or need it was designed for,
         and if it aligns with user expectations and preferences.
        < br />
        <br /> These steps provide a structured approach to prototype development, helping to ensure that the final product meets the desired objectives and delivers value to users.
     
          <br />       
          <br />  
          <br /> 
          <br />
          
        </p>
      </div>
    );
  };
  