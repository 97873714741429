import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import shape5 from "../../assets/images/shapes/shape-10.png";
import shape6 from "../../assets/images/shapes/Search.png";

const Services = () => {
  pageTitle("Services");
  const servicesData = [
    {
      id: 1,
      title: "Patentability Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "shape6",
      href: "/PatentLitigationSupport",
      data_aos_delay: "00ms",
    },
    {
      id: 2,
      title: "Intellectual Prop. Commercialization",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-08",
      href: "/IntellectualProp.Commercialization",
      data_aos_delay: "200ms",
    },
    {
      id: 3,
      title: "Patent Prosecution Support",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-12",
      href: "/PatentProsecutionSupport",
      data_aos_delay: "400ms",
    },
    {
      id: 4,
      title: "MarketResearch",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-04",
      href: "/MarketResearch",
      data_aos_delay: "00ms",
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };
  const servicesDataTwo = [
    {
      id: 1,
      title: "	Patentability Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-01",
      href: "/PatentLitigationSupport",
      data_aos_delay: "00ms",
    },
    {
      id: 2,
      title: "	Validity Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-08",
      href: "/IntellectualProp.Commercialization",
      data_aos_delay: "200ms",
    },
    {
      id: 3,
      title: "	Invalidity Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-12",
      href: "/PatentProsecutionSupport",
      data_aos_delay: "400ms",
    },
    {
      id: 4,
      title: "	FTO Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-04",
      href: "/MarketResearch",
      data_aos_delay: "00ms",
    },
    {
      id: 5,
      title: "   Infringement Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-04",
      href: "/MarketResearch",
      data_aos_delay: "00ms",
    },
    {
      id: 6,
      title: "	Evidence of Use",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-04",
      href: "/MarketResearch",
      data_aos_delay: "00ms",
    },
    {
      id: 7,
      title: "	Claim Charts",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-04",
      href: "/MarketResearch",
      data_aos_delay: "00ms",
    },
    {
      id: 8,
      title: "	State Of Art Search",
      description:
        "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-04",
      href: "/MarketResearch",
      data_aos_delay: "00ms",
    },
  ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}

      <div>
        {/* <br></br>
        <br></br>
        <br></br> */}
        {/* <div className="title__data">
                    
                            <h2 >Enhancing The Results With Our Proven Experience And <span style={{color: "#F734B6"}}>Technology, We offer variety to services.</span> </h2>
                            <br></br><br></br>
                        <div className='row1'>
                        
                            <div className='column1'>
                                <div className='row1'>
                                    <h4 style={{color: "Yellow"}}>•<span>Patent  Prosecution </span>Support</h4>
                                    <br></br>
                                        <div className='column1'>
                                            
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Filing</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Drafting</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	State Of Art Search</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patentability Search</p>
                                        </div>
                                        <vr/>
                                        <div className='column1'>
                                            
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Search (Design)</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Office Action Responses</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Directed Patent Prosecution</p>
                                        </div>
                                </div>
                            </div>
                            <div className='column1'>
                                <div className='row1'>
                                    <h4 style={{color: "Yellow"}}>•<span>Intellectual property </span>Commercialization </h4>
                                    <br>
                                    </br>
                                    <div className='column1'>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	FTO Search</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Evidence of Use</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Inventor Watch </p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Licensing Support</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Monitoring </p>
                                    </div>
                                    
                                    <div className='column1'>
                                        
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Infringement Search</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Due Diligence</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Reverse Engineering</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Portfolio Ranking</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                                    <br></br>
                            
                        <div className='column1'> 
                            <div className='row1'>   
                                <h4 style={{color: "Yellow"}}>•<span>Patent Litigation </span> Support </h4>
                                    <br></br>
                                    <div className='column1'>   
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Claim Charts</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Evidence of Use</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Inventor Watch </p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Licensing Support</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Monitoring </p>
                                    </div>
                                    <div className='column1'> 
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Infringement Search</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Due Diligence</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Reverse Engineering</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Portfolio Ranking</p>
                                    </div>
                            </div>
                        </div>
                        <div className='column1'> 
                            <div className='row1'>   
                                <h4 style={{color: "Yellow"}}>• Market Research </h4>
                                    <br></br>
                                    <div className='column1'>   
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Claim Charts</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Evidence of Use</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Inventor Watch </p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Licensing Support</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Monitoring </p>
                                    </div>
                                    <div className='column1'> 
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Infringement Search</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Due Diligence</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Reverse Engineering</p>
                                            <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Patent Portfolio Ranking</p>
                                    </div>
                            </div>
                            <br></br>
                        </div>
 <br></br>
                        <div className='column1'> 
                            <div className='row1'>   
                                <h4 style={{color: "Yellow"}}>• R&D Support </h4>
                                    <br></br>
                                    <div className='column1'>   
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Data Collection and Analysis</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Prototype Development</p>
                                        
                                        
                                    </div>
                                    <div className='column1'> 
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Technical Writing</p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•    Intellectual Property (IP) Management</p>
                                    </div>
                            </div>
                        </div>

                        <br></br>
                        <div className='column1'> 
                            <div className='row1'>   
                                <h4 style={{color: "Yellow"}}>• Competitive Watch </h4>
                                    <br></br>
                                    <div className='column1'>   
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Product/Service Tracking </p>
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Intellectual Property Monitoring</p>
                                        
                                        
                                    </div>
                                    <div className='column1'> 
                                        <p style={{background: "#ecf0f1",borderRadius:15,margin: 3,padding: 3,color: "#2c3e50"  }}>•	Competitor Analysis</p>
                                        
                                    </div>
                            </div>
                        </div>
                    </div>      */}
      </div>

      {/* <div className="container">
        <div className="service__page p_relative see__pad">
          <div className="auto-container">
            <div className="row"></div>
          </div>
        </div>
      </div> */}
      <section className="service__two p_relative">
        <div className="row">
          {servicesDataTwo.map((item, id) => (
            <div
              className="col-xl-3 col-lg-6 col-md-12 wow fadeInUp animated"
              data-wow-delay={item.data_aos_delay}
              data-wow-duration="1500ms"
              key={id}
            >
              <div className="service__block__two p_relative">
                <div
                  className="service__image"
                  style={{ backgroundImage: `url(${shape5})` }}
                ></div>
                <div className="service__icon">
                  <div className="service__number">
                    <h1>{item.number}</h1>
                  </div>
                  <i className={item.icon}></i>
                </div>
                <div className="service__text">
                  <h4>
                    <Link to={item.href}>{item.title}</Link>
                  </h4>
                  <p>{item.description}</p>
                </div>
                <div className="service__button__two">
                  <Link to={item.href}>{item.buttontext}</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      action="contact.php"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-6 form-group">
                          <input
                            type="name"
                            name="name"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Your Subject "
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone "
                            required=""
                          />
                        </div>
                        <div className="ccol-xl-6 col-lg-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-xl-12 form-group message-btn btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send your Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne></FooterOne>
    </>
  );
};

export default Services;
