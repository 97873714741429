export const PatentDueDiligence= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >

         <br />
         <br />
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >  Patent Due Diligence</span>{" "} 
         <br /> 
         <br />
         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  At Matic Research, 
           </span>{" "} 
           our Patent Due Diligence service involves a meticulous examination of your patent portfolio. 
           We thoroughly assess the quantity and nature of intellectual property (IP) owned or licensed by 
           corporations, businesses, or individuals. Our evaluation extends to understanding how your 
           company acquires and protects its intellectual property assets.
          
         <br />
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x21DB; </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           > Here is the advantage of a Patent Due Diligence 
           </span>{" "} 
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
           The amount and caliber of intellectual property they own, enabling third parties to assign a value to them.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            To determine which intellectual property is not being used at the moment and whose maintenance expenses are being paid needlessly.
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            To determine whether each and every IP asset is sufficiently safeguarded.
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
             To make sure you have all the required permissions to use your intellectual property (IP) assets.
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            To determine if someone else is violating your intellectual property right.
           
           
          <br />
          <br />
        </p>
      </div>
    );
  };
  