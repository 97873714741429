export const StateoftheArt = () => {
  return (
    <div
      style={{
        background: "#00061A",
        color: "#fff",
        marginTop: "0px",
        padding: "0 50px",
      }}
    >
      <p
        style={{
          textAlign: "justify",
          margin: "0",
          fontSize: "20px",
        }}
      >
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}>
          {" "}
          State of art Analysis{" "}
        </span>{" "}
        <br />
        <br />
        Explore new technological domains or introduce groundbreaking products
        with confidence through
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          {" "}
          Matic Research's
        </span>{" "}
        State-of-the-Art Search service. By conducting thorough analyses, we
        evaluate both the weaknesses and strengths of technology within your
        chosen field, empowering your organization to make informed decisions
        and stay ahead of the curve.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          &#x21DB;{" "}
        </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}>
          {" "}
          State-of-the-Art search enables an organization to uncover or
          identify...
        </span>{" "}
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Spotting emerging trends.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Exploring potential partners.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Conducting an analysis of past performance.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Identifying the current competitors in the market.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Assessing the most recent advancements in the technological environment.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Reassessing or reexamining previous Advancements/Developments/Research.
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          &#10687;
        </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}>
          Why Opt for Matic Research for State-of-the-Art Analysis…?
        </span>{" "}
        <br />
        Choose Matic Research for State-of-the-Art Analysis to empower your
        company with a comprehensive perspective. Our expertise facilitates
        informed decisions crucial for successful entry into new technological
        areas.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        We employ carefully crafted search queries on diverse commercial as well
        as open-source patent databases.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Our researchers perform additional analysis on the results generated by
        these search queries.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Subsequently, a comprehensive report is provided, aiding organizations
        and intellectual property, individual and research teams.
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >
          {" "}
          ✔{" "}
        </span>{" "}
        Our State-of-the-Art search is conducted on an extensive scale to
        capture pertinent references within the field/domain.
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  );
};
