export const LicensingSupport= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
         <br />
         <br />
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Licensing Support </span>{" "} 
         <br /> 
         <br />
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >Matic Research 
           </span>{" "} 
           provides all-inclusive services that enable you to confidently negotiate the complexity of patent licensing. Drafting, negotiating, and overseeing patent licensing 
           that is customized to your unique requirements and objectives is our team's area of expertise. 
    
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research
          </span>{" "}
          offers strategic advice and help throughout the licensing process, whether you're a patent holder hoping to make money off of your discoveries or a licensee hoping to get important technology. You can rely on us to make sure that your patent licensing contracts are solid,
          equitable, and designed for both parties' benefit in the marketplace.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#10687;</span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           > Why Should You License Your Patent to other..? 
           </span>{" "} 
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            An approved method that secures royalties and turns your product idea into a reality without requiring manufacturing capital.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
             Launching the product quickly and easily requires licensing your patent to a reputable business.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            Maintain your patent ownership.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            Simple to penetrate international marketplaces.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            Removing the possibility of patent infringement.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#10687;</span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           > What are the benefits of patent licensing for both parties…? </span>{" "} 
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x21DB; </span>{" "}
           <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>   To the holders of patents: 
            </span>{" "}
           <br />

           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
           Your intellectual property rights are still yours.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
           You will be able to get revenue from your intellectual property.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
           Your invention will find new applications in new markets.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
            Increase the marketability of your invention.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x21DB; </span>{" "}
           <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>  To the holder of a patent license:
            </span>{" "}
           
          
        
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
            Generate fresh market prospects.
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
            Minimal expenditure in comparison to the entire process of developing a product and obtaining a patent for it.
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
            Speeding up the process by releasing the end product quickly in the market.
           <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
            Obtain a competitive advantage over your rivals.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#10687;</span>{" "} 
           <span  style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           > What makes Matic Research a preferred choice for your patent licensing …?
           </span>{" "}
           <br />
           <br />
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research, 
           </span>{" "}
           we leverage databases to identify companies producing products similar to yours, presenting you with a curated list of manufacturers potentially interested in your offerings. Our specialized patent licensing service is meticulously designed to support 
           IP attorneys and patent owners in pinpointing the most valuable aspects of their portfolios.
           <br />✔ Doing a study of validity and infringement to determine the patent's worth.
           <br />✔ Our team will assist you in obtaining the patents that will raise the value of your portfolio through cross-licensing.
           <br />✔ Technology assessment.
           <br />
           <br />

           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research,  
           </span>{" "}
           we specialize in assisting our clients in selecting optimal patent licensing strategies 
           through meticulous portfolio analysis. Our comprehensive background investigations aim to 
           streamline the licensing process for interested parties while enhancing negotiation positions 
           to maximize the value extracted from patents. Trust Matic Research to simplify licensing and 
           amplify patent value through strategic insights.
          <br />
          <br />
          <br />
        </p>
      </div>
    );
  };
  