export const DataCollectionAndAnalysis = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "27px" }}
          > Data Collection and Analysis </span>{" "} 
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > At Matic Research, </span>{" "} a data collection and analysis project involves
           gathering pertinent data from diverse sources, meticulously organizing and processing it, 
           and subsequently analyzing the data to extract meaningful insights and conclusions. 
           These initiatives are undertaken to address specific research queries, tackle challenges, 
           or enlighten decision-making processes across various sectors such as business, academia,
            healthcare, and government.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          
           <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          > Key components of a data collection and analysis project at Matic Research encompass:
           </span>{" "} 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x27AA;
           </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
          Project Planning: </span>{" "} Defining project objectives, scope, and timelines 
          while pinpointing data sources and collection methodologies.
           <br /> 
           <br /> 
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x27AA;
           </span>{" "} 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Data Collection: </span>{" "} 
         Collating data from primary and/or secondary sources via methodologies like surveys, interviews,
         observations, experiments, or accessing existing datasets.
         <br /> 
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x27AA;
           </span>{" "} 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Data Cleaning and Preparation:   </span>{" "}
         Streamlining collected data for accuracy, consistency, and completeness by tasks 
         such as eliminating duplicates, rectifying errors, and formatting data for analysis.
          <br />
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x27AA;
           </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Data Analysis: </span>{" "} 
          Employing statistical or analytical techniques for exploration, interpretation, and conclusion
           derivation, including descriptive and inferential statistics, data visualization, or advanced
          analytics like machine learning.
          <br />
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x27AA;
           </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Interpretation and Reporting: </span>{" "} 
          Interpreting analysis findings within project objectives' framework and presenting outcomes
         effectively through reports, dashboards, presentations, or other communication mediums for stakeholders.
          < br/> 
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x27AA;
           </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Action Planning: </span>{" "} 
          Utilizing insights derived from data analysis to influence decision-making,
          craft recommendations, or steer future actions and strategies.
          <br />
          < br /> 
          Overall, 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research's 
          </span>{" "}
          data collection and analysis endeavors aim to translate 
          raw data into actionable insights that drive informed decision-making and facilitate project success.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >How we perform Data Collection and Analysis...?
          </span>{" "}
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }}
          > ✔  </span>{" "} 
          
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Defining Objectives and Scope:   </span>{" "} 
         Begin by clearly outlining the objectives of the data collection and analysis process and defining the
        scope of the project. This involves identifying the specific research questions or 
        problems to be addressed and determining the parameters within which data will be collected and analyzed.
          < br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }}
          > ✔  </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>  Selecting Data Sources:    </span>{" "} 
          Identify the sources from which relevant data will be collected. This may include primary sources such as surveys, 
          interviews, or observations, as well as secondary sources such as existing datasets, literature reviews, or publicly available information.
          < br/> 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }}
          > ✔  </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>  Collecting Data: </span>{" "} 
           Gather the necessary data from the selected sources using appropriate methods and techniques.
           This may involve designing and administering surveys, conducting interviews, extracting data from databases, or performing field observations.     
         <br />
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }}
          > ✔  </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> Cleaning and Preparing Data: </span>{" "} 
          Process the collected data to ensure accuracy, consistency, and completeness. This includes tasks such as removing 
          duplicates, correcting errors, standardizing formats, and dealing with missing or incomplete data.
          < br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }}
          > ✔  </span>{" "} 

          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>  Analyzing Data: </span>{" "} 
          Apply statistical or analytical techniques to analyze the collected data and derive meaningful insights. 
          This may involve descriptive statistics to summarize the data, inferential statistics to make inferences
           or predictions, data visualization to present findings visually, or advanced analytics
            methods such as machine learning.
          < br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "22px" }}
          > ✔  </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>  Interpreting and Communicating Results:    </span>{" "} 
          Interpret the findings of the data analysis in the context of the research objectives and 
          communicate the results effectively to stakeholders. This may involve creating reports, 
          presentations, dashboards, or other visualization tools to convey the insights and conclusions 
          drawn from the data analysis.
          <br />       
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  