export const CommercializationDescription = () => {
  return (
    <div
      style={{
        background: "#00061A",
        color: "#fff",
        marginTop: "0px",
        padding: "0 50px",
      }}
    >
      <p
        style={{
          textAlign: "justify",
          margin: "0",
          fontSize: "20px",
        }}
      >
        Discover
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          {" "}
          Matic Research
        </span>
        {"  "}
        your leading hub for expert Patent Commercialization Services. We excel
        in harnessing smart IP management and commercialization strategies to
        transform innovative concepts and inventions into valuable assets.
        Through close collaboration, our seasoned team works with clients to
        identify, safeguard, and capitalize on their intellectual property
        portfolios. From crafting licensing agreements to fostering partnerships
        and facilitating product development, we offer customized solutions to
        optimize the commercial value of your IP assets. Entrust us to spearhead
        innovation and cultivate avenues for growth with our effective Patent
        Commercialization strategies.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}>
          {" "}
          Why you should choose Matic Research for your Intellectual Property
          (IP) Commercialization…?
        </span>{" "}
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          {" "}
          ✔ Matic Research
        </span>{" "}
        boasts a proven track record and proficiency in assisting clients with
        patent monetization. Our experience extends to aiding individual
        inventors, institutions, organizations, and private businesses in
        developing effective commercialization strategies. We possess the
        expertise to formulate business models, essential strategies, and to
        create successful commercialization and Intellectual Property strategies
        tailored for both domestic and international markets.
        <br />
        <br />
      </p>
    </div>
  );
};
