import { useParams } from "react-router-dom";
import { PatentProsecutionSupport } from "./Components/PatentProsecutionSupport";
import { PatentDescription } from "./Components/PatentDescription";
import { DirectedPatentProsecution } from "./Components/DirectedPatentProsecution";
import { OfficeActionResponse } from "./Components/OfficeActionResponse";
import { PatentabilitySearch } from "./Components/PatentabilitySearch";
import { PatentDrafting } from "./Components/PatentDrafting";
import { PatentSearchDesign } from "./Components/PatentSearchDesign";
import { PatentFiling } from "./Components/PatentFiling";
import { useState } from "react";
import FooterOne from "../../Footer/FooterOne";

export const PatentProsecution = () => {
  const subtopic = useParams();
  const value = subtopic[Object.keys(subtopic)[0]];

  const [, setSelectedComponent] = useState("PatentDescription");

  const renderComponent = () => {
    switch (value) {
      case "PatentDescription":
        return <PatentDescription />;
      case "Patent Filing":
        return <PatentFiling />;
      case "Patent Drafting":
        return <PatentDrafting />;
      case "Patentability Search":
        return <PatentabilitySearch />;
      case "Patent Search (Design)":
        return <PatentSearchDesign />;
      case "Office Action Responses":
        return <OfficeActionResponse />;
      case "Directed Patent Prosecution":
        return <DirectedPatentProsecution />;
      default:
        return <PatentDescription />;
    }
  };

  return (
    <div>
      <PatentProsecutionSupport onSelectComponent={setSelectedComponent} />
      <div>{renderComponent()}</div>
      <FooterOne />
    </div>
  );
};
