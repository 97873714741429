export const FTOSearch= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
       <br />
       <br /> 
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >  FTO Search </span>{" "} 
         <br /> 
         <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >
          At Matic Research,
          </span>{" "}
          Our Patent Commercialization Service includes thorough Freedom to Operate (FTO) searches. We conduct a comprehensive analysis to uncover active patents that may obstruct a company's ability to
          produce, manufacture, sell a product, or implement a process within specific jurisdictions. Leveraging advanced technology and technical acumen, our expert researchers identify potential infringement risks and deliver strategic solutions to effectively mitigate these risks, ensuring smooth patent commercialization for our clients.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >
            Matic Research
          </span>{" "}
           stands out as a premier provider of intellectual property research and various analysis services. 
           Our mission is to assist businesses across diverse industries in making informed decisions, minimizing risks, 
           and optimizing their competitive edge. Among our key offerings is the Freedom to Operate (FTO) search, a service 
           designed to help businesses identify and diminish potential infringement threats prior to the introduction of new 
           products or services in the marketplace.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
            
          >
             <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          Following is the Benefit of FTO (Freedom to Operate) search…?
          <br />
          </span>{" "}
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "} 
          Conducting an FTO search conserves significant time and resources, empowering you to establish a secure and well-suited business framework. 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           Ease in securing the introduction of your product to the market.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           FTO search also enables the development team to uncover innovative ideas.
.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >
            {" "}
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          What is Matic Research's approach to processing your FTO (Freedom to Operate) Search…?
          <br />
          </span>{" "}
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
          Our team formulates diverse search strategies and techniques
           to pinpoint the most relevant patents to your invention that may be subject to infringement.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           Our objective is to identify keywords that aid in the identification of an invention or product within the claims section of grant or live 
           patents or in-process pending patent applications excluding the abounded patent applications.  
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
            Examining the patent involves scrutinizing individual claims, investigating entry barriers, and identifying patents and technologies that may impede the client's intellectual property patent quest. Eliminating irrelevant results and retaining a subset of patents presenting an issue is part of the process.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
            Evaluate potential issues by examining factors such as patent status, possible expiration, and/or search. Compile an invalidity opinion based on the findings.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
            Communicate the results to the client, highlighting the potential intellectual property (IP) issues and solutions, and provide recommendations for a Proactive plan.
          <br />
          <br />
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          What makes Matic Research a preferred choice for FTO (Freedom to Operate) Search…?
          < br/>
          </span>{" "}
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >
          ✔ Matic Research
          </span>{" "} boasts a team of proficient experts with specialized skills in essential domains.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
          A thorough exploration for prior art utilizing more than 10+ databases.
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}
           Possessing the ability to communicate in multiple languages, spanning nearly 120+ countries.
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ Matic Research
          </span>{" "}  provides extensive regional database coverage with multiple regional language capabilities, including CN, JP, and KR, ensuring the delivery of accurate results.
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "} Comprehending the legal frameworks of various nations, assessing the broadest claim scope, and evaluating the likelihood of infringement.
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "} We ensure transparency in the search process by delivering interim results and maintaining continuous communication with clients.
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "}<span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research
          </span>{" "} conducts structure and sequence-based searches, along with structure represented by the Markush notation.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "} Our pricing plans are tailored to meet the specific requirements of each client.
         <br />
         <br /> 
        </p>
      </div>
    );
  };
  