export const Technicalwriting = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
         <br /> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          > Technical Writing </span>{" "} 
         <br /> 
         <br />

         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > At Matic Research,</span>{" "} our technical writing services involve crafting articulate content
           that conveys intricate technical concepts in a lucid, succinct, and accessible manner. 
           Our expertise spans a diverse array of documents, including user manuals, instruction guides,
            software documentation, technical reports, white papers, and scientific research papers.
          <br />
          <br />
          Our paramount objective in every technical writing project is to enhance comprehension and usability
          of technical information tailored to specific audiences, whether they are end-users, stakeholders, 
          engineers, scientists, or other professionals. Collaborating closely with subject matter experts, 
          we meticulously gather and refine technical details to ensure accuracy and relevance throughout 
          the documentation process.
          <br />
          <br /> 
          Following a meticulously structured approach encompassing planning, research, drafting, editing, and 
          finalization, we uphold meticulous attention to detail, clarity of expression, adherence to style 
          guidelines, and seamless organization of information. Our technical writing projects serve as 
          indispensable tools for effective communication of technical concepts, procedures, and insights,
          thereby supporting diverse industries, businesses, and organizations in accomplishing their objectives 
          with precision and clarity.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          > How we perform Technical Writing...? </span>{" "} 
          <br />

          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> 
         At Matic Research, </span>{" "} our technical writing process involves
          the following steps:
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
        Understanding Audience: </span>{" "} Analyze the characteristics, needs, 
         and knowledge level of the target audience to customize the content appropriately.
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "}  
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Thorough Research: </span>{" "} Gather pertinent information from various sources,
          including subject matter experts, technical documents, research papers, and online resources.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Structuring Outline: </span>{" "} Develop a well-organized outline delineating main sections, 
         sub-sections, and key points to be addressed in the document.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Initial Drafting: </span>{" "} Craft the initial draft of the document with a focus on clarity,
          accuracy, and logical organization, employing clear and concise language devoid of unnecessary jargon.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Comprehensive Revision:  </span>{" "} Review and revise the draft meticulously to enhance clarity, 
         coherence, and readability, ensuring adherence to style guidelines and consistency throughout.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Precise Editing:  </span>{" "} Edit the revised draft for grammar, punctuation, spelling,
          and stylistic errors, maintaining uniformity in formatting, terminology, and tone.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Incorporating Feedback:  </span>{" "} Solicit feedback from subject matter experts or stakeholders 
         to validate the accuracy and efficacy of the content, making necessary revisions accordingly.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Finalization and Proofreading:  </span>{" "} Make any essential final adjustments based on feedback
          and meticulously proofread the document to detect any remaining errors or inconsistencies.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}> 
         Publication:  </span>{" "} Publish or distribute the finalized document to the intended audience 
         through suitable channels, including print, digital platforms, or online repositories.
          <br />
          <br />       
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  