export const PatentDrafting = () => {
  return (
    <div style={{ background: "#00061a", padding: "0 50px" }}>
      <p
        style={{
          textAlign: "justify",
          fontSize: "20px",
          color: "#fff",
          marginTop: "0px",
        }}
      >
        <br /> 
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
        >
          Patent Drafting {" "}
        </span>
        <br />
        Collaboration between the patent attorney and the inventor is essential.
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Matic Research {" "}
        </span>  specializes in the fundamental aspect of patent
        applications, which is patent drafting. A patent provides the inventor
        with the right to prevent others from exploiting their invention without
        their consent. The proper drafting of a patent is crucial because the
        grant of your patent relies on how the patent application is well
        drafted.
        <br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >  We eliminate these common errors in your patent application
        mistakes {" "}
        </span>
        <br />  
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >  {" "} </span> 
        Failure to explain the function of the invention when it is overly
        obvious.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >   {" "} </span>  Avoiding vagueness or oversimplification in your application.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > {" "} </span>  Focusing on specific, narrow claims for your invention rather than
        overly broad claims.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > {" "} </span>  Eliminating any miswording or ambiguities in your patent application.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        > Consider the following factors when composing your patent: {" "}
        </span>
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}  
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >  {" "} </span> The patent writing process demands meticulous attention, whether you're 
        filing a provisional patent application or a complete application/Non- provisional patent application.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        >  {" "} </span> Investing in professional assistance for claim drafting can
         prove to be a valuable investment.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > {" "} </span> Ensure that all documentation is completed accurately 
        and comprehensively to avoid disqualification.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > {" "} </span> Ensure that claim match with the specification of the invention.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
        > {" "} </span> Explain in the details of best mode of working.
        <br />
        <br />
        Our team of experienced intellectual property (IP) experts excels in drafting top-tier patent applications. We adhere to a rigorous methodology that incorporates multiple quality checks,
        efficiently navigating various stages of the patent drafting process.
        <br />
        <br />
        To create a comprehensive specification/detailed specification,  
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Matic Research  {" "} </span> initiates the process by providing an invention disclosure form to an inventor/applicant. This form allows the inventor/ applicant to provide sufficient information about the innovation to the patent attorney. Subsequently, 
        we begin drafting the patent application, starting with the drafting of the claims.
        <br />
        <br />
       




        
      </p>
    </div>
  );
};
