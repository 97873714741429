export const InvaliditySearch = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >      
        <br /> 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
           >   Invalidity Search</span>{" "} 
          <br /> 
          <br />
 
        
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >Matic Research 
          </span>{" "}
          conducts patent invalidity searches with precision and focus. These searches delve into previously
           published information, spanning patents, non-patent materials, and accessible data predating a 
           patent's filing or priority date. The objective is to unearth evidence that may cast doubt on the
            uniqueness and inventive nature of the patented idea. Our search serves dual purposes:
             to affirm the legitimacy of a patent's enforceability through a validity check or to contest 
             its claims through an invalidity check. Moreover, these searches are often employed to 
             evaluate a patent's resilience against validity challenges. Trust 
             <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research 
          </span>{" "} for thorough and insightful patent invalidity searches.
          <br />
          <br />
          We specialize in invalidation efforts across different realms of intellectual property,
          including Patent Trial and Appeal Board (PTAB) proceedings, Inter Partes Review (IPR), cases within the International Trade Commission (ITC),
          Covered Business Method (CBM) reviews, and Post-Grant Review (PGR) processes.
          <br />
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Why you should choose Matic Research for Invalidity Search…?
          </span>{" "}
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} 
            Utilization of diverse search approaches to contribute to an effective strategy for 
            identifying pertinent prior art.  
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} 
            Proficient use of multiple patent and non-patent databases with efficiency.  
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >&#x21DB; </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>  
            The effective search strategy comprises:</span>{" "} 
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> 
            ✔  </span>{" "} Extracting insights from the file wrapper: </span>{" "}
          
          Assessing cited references in the patent's file wrapper (along with related ones),
          verifying their relevance, exploring reasons for their inability to invalidate recent claims, 
          and extracting insights for the initial step.        
          <br />


          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> 
            ✔  </span>{" "}  Thorough exploration of patent classes:  </span>{" "} 
            Delving beyond just the patent's classes, diving deep into the domain to pinpoint the most specific
             class targeting the features. This involves examining cited results, their related citations, 
             and relevant sources like free and paid databases Patents.
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  
            </span>{" "}  Comprehensive perspective analysis:  </span>{" "} 
            Prior to commencing the search, brainstorming with the expert team to explore various potential interpretations
             and directions concerning the key features. 
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} 
            Identification of segments within prior art that elucidate the relevancy of a reference.  
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} 
            A comprehensive and self-explanatory invalidation search report including a thorough prior art analysis, web links for detailed specification review,
            visual aids, legal status and their complete bibliographic data of the references.  
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  