export const DirectedPatentProsecution = () => {
  return (
    <div style={{ background: "#00061a", padding: "0 50px" }}>
      <p
        style={{
          textAlign: "justify",
          fontSize: "20px",
          color: "#fff",
          marginTop: "0px",
        }}
      >
        <br />
        <span
          style={{ fontWeight:"bold", color: "#fff", fontSize: "30px" }}
        > Directed Patent Prosecution </span>
        <br />
        <br />

        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Matic Research’s </span>

         Patent Prosecution service suite Elevates the worth of
        your patent portfolio is crucial, and directed prosecution emerges as a
        pivotal strategy to generate substantial value for your intellectual
        property (IP) assets.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          What are Directed Patent Prosecution’s primary Goals...?{" "}
        </span>
        <br /> <span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span>
        
         Assisting in enhancing the monetization potential of your patent
        applications.
        <br /><span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span> Guarantees a heightened Return on Investment for your existing
        portfolio.
        <br /><span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span> Increased likelihood of establishing comprehensive mapping
        Evidence-of-Use (EoU) in the future.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "}
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          What advantages does Directed Patent Prosecution offer…?{" "}
        </span>
        <br /> Directed prosecution offers several advantages for patent
        protection that span a wide range of technologies or markets. It lessens
        the likelihood that your patent will be deemed invalid in a patent
        dispute. 
        <br /> A few advantages of directed prosecution are as follows:
        <br />
        <span
        style={{ fontWeight: "bold",  color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span>  Building a thorough and technically robust IP portfolio through the
        incorporation of new continuing patent applications. 
        <br />
        <span
        style={{ fontWeight: "bold",  color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span>  Enhancing the IP portfolio's defense against lawsuits. 
        <br />
        <span
        style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span> The potential for licensing and monetizing the IP portfolio will be elevated.

        <br />
        <span
        style={{ fontWeight: "bold",  color: "#ff0d76", fontSize: "20px" }}
        > ✓ {" "} </span> Producing additional Evidence-of-Use (EOUs) through the utilization of
        continuation patent applications to maximize returns with minimal
        investments.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "}
        <span
          style={{ fontWeight: "bold", color:"#fff", fontSize: "25px" }}
        > How Matic Research aids in Directed Patent Prosecution…?
        </span>{" "}
        <br /> <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Our expert’s team
        </span>{" "} can assist companies in reinforcing their IP portfolios by overseeing
        the prosecution of their patent applications, ensuring timely decisions
        regarding the filing of continuation patent applications within the
        3-month timeframe. 
        <br />
        <br />
        Upon receipt of the Notice of Allowance (NOA), our
        expert’s team can aid in identifying essential elements or embodiments
        absent from the patent claims by scrutinizing the patent description.
        Additionally, we explore relevant products or technologies associated
        with the same. 
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Our expert’s team
        </span>{" "}
         can support you in creating a
        specification mapping, also known as an evidence-of-use (EoU) chart.
        This chart includes the claimed elements of the Notice of Allowance
        (NOA) application and the identified missing key elements or embodiment
        from the patent description, aligned with the relevant product. The
        specification mapping serves as a valuable tool for drafting new claims
        that anticipate potential infringement scenarios in the future.
        <br />
        <br />
      </p>
    </div>
  );
};
