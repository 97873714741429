import React, { useState } from "react";
import screenshot from "../../assets/PatentProsecution.jpg";
import {
  buttonStyle,
  containerStyle,
  containerHeadingStyle,
  containerParaStyle,
} from "../../Styles/styles";
import { useNavigate } from "react-router-dom";
import "./PatentProsecutionSupport.css"; // Link to the CSS file for animations

export const PatentProsecutionSupport = ({ onSelectComponent }) => {
  const Navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonArr = [
    {
      name: "Patent Filing",
      component: "PatentFiling",
      link: "/patent/patent-prosecution/Patent Filing",
    },
    {
      name: "Patent Drafting",
      component: "PatentDrafting",
      link: "/patent/patent-prosecution/Patent Drafting",
    },
    {
      name: "Patentability Search",
      component: "PatentabilitySearch",
      link: "/patent/patent-prosecution/Patentability Search",
    },
    {
      name: "Patent Search (Design)",
      component: "PatentSearchDesign",
      link: "/patent/patent-prosecution/Patent Search (Design)",
    },
    {
      name: "Office Action Responses",
      component: "OfficeActionResponse",
      link: "/patent/patent-prosecution/Office Action Responses",
    },
    {
      name: "Directed Patent Prosecution",
      component: "DirectedPatentProsecution",
      link: "/patent/patent-prosecution/Directed Patent Prosecution",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        background: "#00061A",
        height: "100%",
        paddingLeft: "50px",
        paddingRight: "10px",
        marginBottom: "0px",
      }}
    >
      <div
        className="col-1 slide-left"
        style={{ width: "50%", paddingTop: "50px" }}
      >
        <h1
          style={{
            color: "#fff",
            fontSize: "55px",
            lineHeight: "1.2",
            marginBottom: "0px",
            textAlign: "left",
            background: "#00061A",
            marginTop: "0px",
          }}
        >
          Patent Prosecution Support
        </h1>
        <p
          style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "20px",
          }}
        >
          Comprehensive patent prosecution support services for navigating the
          <br /> complexities of patent application processes.
        </p>
        {buttonArr.map((item, index) => (
          <button
            key={index}
            style={{
              border: "2px solid #ff0d76",
              color: hoveredButton === index ? "#ff0d76" : "#fff",
              background: "#00061a",
              borderRadius: "30px",
              borderWidth: "3px",
              margin: "10px",
              padding: "20px",
              cursor: "pointer",
              width: "auto",
              fontSize: "18px",
              fontWeight: "600",
            }}
            onMouseEnter={() => setHoveredButton(index)}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => {
              onSelectComponent(item.component);
              if (item.link) Navigate(item.link);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div
        className="col-1 slide-right"
        style={{ width: "50%", paddingRight: "40px" }}
      >
        <img
          style={{
            height: "500px",
            width: "100%",
            marginTop: "50px",
          }}
          src={screenshot}
          alt="Patent Prosecution Screenshot"
        />
      </div>
    </div>
  );
};
