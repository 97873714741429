import { useState } from "react";
import { MarketResearchHeading } from "./Components/MarketResearchHeading";
import { MarketResearchDescription } from "./Components/MarketResearchDescription";
import { CompetitiveAnalysis } from "./Components/CompetitiveAnalysis";
import { LandscapeAnalysis } from "./Components/LandscapeAnalysis";
import { ProductLinecomparison } from "./Components/ProductLinecomparison";
import { StateoftheArt } from "./Components/StateoftheArt";
import { useParams } from "react-router-dom";
import FooterOne from "../../Footer/FooterOne";

export const MarketResearch = () => {
  const subtopic = useParams();
  const value = subtopic[Object.keys(subtopic)[0]];
  const [, setSelectedComponent] = useState("MarketResearchDescription");

  const renderComponent = () => {
    switch (value) {
      case "Competitive Analysis":
        return <CompetitiveAnalysis />;
      case "Landscape Analysis":
        return <LandscapeAnalysis />;
      case "Product Line Comparison":
        return <ProductLinecomparison />;
      case "State of Art Analysis":
        return <StateoftheArt />;
      default:
        return <CompetitiveAnalysis />;
    }
  };

  return (
    <div>
      <MarketResearchHeading setSelectedComponent={setSelectedComponent} />
      <div>{renderComponent()}</div>
      <FooterOne />
    </div>
  );
};
