export const PatentSearchDesign = () => {
  return (
    <div style={{ background: "#00061a", padding: "0 50px" }}>
      <p
        style={{
          textAlign: "justify",
          fontSize: "20px",
          color: "#fff",
          marginTop: "0px",
        }}
      >
        <br />
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
        >
          Patent Search (Design)
        </span>
        <br />
        <br />
        Design patent offered by <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          Matic Research
        </span> ensures legal protection
        bestowed upon the artistic and aesthetic aspects of a functional
        product. The design patent search service gives you insights regarding
        current active design patents that are in-force similar to the design in
        question.
        <br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
          How Matic Research Improves the Design Patent Search Experience for You…?
        </span>
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        >
          Matic Research
        </span> offers significant time and cost savings through our
        comprehensive design patent search services. We leverage a wide array of
        tools and employ strategic methods to deliver highly accurate results in
        design patent searches. Our utilization of Locarno classes proves
        instrumental in retrieving the most relevant prior art. Our team breaks
        down design patents into individual modules, subjecting each module to
        meticulous analysis and examination thorough.
        <br />
        <br />
      </p>
    </div>
  );
};
