import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PatentProsecution } from "../maticPages/PatentProsecution/PatentProsecution";
import { PatentLitigation } from "../maticPages/PatentLitigationSupport/PatentLitigation";
import { CommercializatonProperty } from "../maticPages/ComDes/CommercializationProperty";
import { MarketResearch } from "../maticPages/MarketResearch/MarketResearch";
import { RDSupport } from "../maticPages/RDSupport/RDSupport";
import { CompetitiveWatch } from "../maticPages/CompetitiveWatch/CompetitiveWatch";
import { Navbar } from "../Navbar/Navbar";

const AppRoutes = () => {
  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <Navbar />
      <Routes>
        <Route path="/competitive-watch/*" element={<CompetitiveWatch />} />
        <Route path="/rd-support/*" element={<RDSupport />} />
        <Route path="/market-research/*" element={<MarketResearch />} />
        <Route path="/patent-prosecution/*" element={<PatentProsecution />} />
        <Route path="/patent-litigation/*" element={<PatentLitigation />} />
        <Route
          path="/commercialization-property/*"
          element={<CommercializatonProperty />}
        />
      </Routes>
    </div>
  );
};

export default AppRoutes;
