export const CompetitiveAnalysis = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
        <br /> 
        <span style={{ fontWeight: "bold", color:"#fff", fontSize: "25px" }}
          > Competitive Analysis </span>{" "} 
        <br />
        <br />
         
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x2B46; </span>{" "} 
           <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Matic Research: Comprehensive Competitor Analysis for Strategic Insights: </span>{" "} 
          <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > At Matic Research,  </span>{" "} 
           we specialize in providing detailed competitor analyses to help you pinpoint your company’s
            strengths and weaknesses relative to each competitor. A competitor analysis, also known as a 
            competitive analysis, is a crucial process of identifying and researching the marketing strategies of your industry rivals.
          <br />
          <br />
          Our expertise allows us to conduct both high-level overviews and in-depth examinations of specific
           aspects of your competitors’ businesses. This flexibility ensures that we can tailor our analysis 
           to meet the unique needs and goals of your business. Whether you need 
           a broad understanding of the competitive landscape or a focused study on particular competitors, 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> Matic Research  </span>{" "}
          delivers the insights necessary for strategic decision-making and competitive advantage.
         <br /> 
         <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}>
            Why Regular Competitive Analysis with Matic Research Matters...?  </span>{" "} 
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> ✔   </span>{" "} 
          Regularly conducting competitive analyses with Matic Research can help you.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> ✔  </span>{" "} 
          Gain a deep understanding of your market and industry dynamics.
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> ✔  </span>{" "} 
          Benchmark your performance against that of your competitors. 
          < br/> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> ✔  </span>{" "} 
           Identify strategic gaps and opportunities your competitors are leveraging. 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> ✔  </span>{" "} 
          Develop targeted strategies that highlight your unique value proposition. 
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> ✔  </span>{" "} 
          Stay informed about market trends to maintain a competitive edge.
          < br />  
          < br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >How we perform Competitive Analysis...?
          </span>{" "}
          <br />

         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "23px" }}> 1.
         </span>{" "} 
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Identification of Target Competitors:
         </span>{" "} 
         
         Pinpoint key competitors within your industry to focus your analysis. This foundational step ensures that your competitive 
         strategy is directed towards relevant market players.
          < br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "23px" }}> 2. </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
         Gathering Information about Target Competitors:  </span>{" "} 
          Collect comprehensive data on your identified competitors, including their marketing strategies, product offerings, and market positioning. This information is crucial for a thorough competitive analysis. 
          < br/> 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "23px" }}> 3. </span>{" "}  
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "23px" }}> 
            Analyze Target Competitors’ Strengths and Weaknesses:  </span>{" "} 
          Evaluate the strengths and weaknesses of your competitors to understand their market advantages and vulnerabilities. This analysis helps in identifying areas where you can outperform them.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "23px" }}> 4. </span>{" "} 
            
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "23px" }}> 
          Analyze Determine Your Competitive Advantage:  </span>{" "}  
          Leverage the insights gained to define your unique value proposition. Clearly articulating your competitive edge allows you to develop strategies that set you apart in the market.
          < br />
          


           
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  