export const OfficeActionResponse = () => {
  return (
    <div style={{ background: "#00061a", padding: "0 50px" }}>
      <p
        style={{
          textAlign: "justify",
          fontSize: "20px",
          color: "#fff",
          marginTop: "0px",
        }}
      >
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
        >
          Office Action Responses
        </span>
        <br />
        <br />
        <span
          style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
        > Matic Research 
        </span> proven track record of office action responses from
        various domains has led inventors successfully defend their inventions
        with granted patent families. We carefully consider and address each of
        the examiner's concerns, addressing many different objections and
        rejections, including those pertaining to USC 101, USC 102, USC 103, and
        USC 112.
        <br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "} 
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
        >
            How do Matic Research respond an Office action...?
        </span>
        <br /> To file a suitable response to office action, we must identify the points that the
        examiner has recognized. We do this by:
        <br />


        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         >  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Making changes to specifications, claims, and drawings.
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         >  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Presenting strong proof that the examiner might not have
        correctly understood the prior art.
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         >  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Significant differences between the claimed invention in
        question and the prior arts, and elaboration by reference.
        <br /> 
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
         > What makes Matic Research the preferred choice for your “Office
          action” responses…?</span>
        <br />

         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Specialists working in-house and attorneys-at-law with practical
        expertise in creating a successful prosecution plan for patent
        applications in any country.
         <br />  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Experienced individuals from important
        technological fields to refute the examiner's technical claims.
        <br />  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Strategic cooperation with several countries legal specialists.
        <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span> Outstanding quality at a budget-friendly price.
        <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
         > <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
         </span> We can do the laborious work, like a thorough technical analysis
        of the examiner's references and pinpointing the precise distinctions
        between the cited art and the invention, for clients who wish to frame
        their responses. This will free up the attorney's time when drafting the
        office action response.
        <br />
        <br />
      </p>
    </div>
  );
};
