export const PatentFiling = () => {
  return (
    <div style={{ background: "#00061a", padding: "0 50px" }}>
      <br />
      <p
        style={{
          textAlign: "justify",
          fontSize: "20px",
          color: "#fff",
          marginTop: "0px",
        }}
      >
        <span
          style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
        >
          Patent Filing
        </span>
        <br /> 
        <br />
        <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > Matic Research’s 
           </span>{" "} 
         innovative approach ensures seamless interactions,
        guaranteeing that you stay informed at all times. When it comes to the
        filing process, it begins with the inventor drafting and reviewing the
        inventor/applicant, which is then submitted to the government patent
        office. If you are in the early stages of developing of your invention,
        you have the option to file a provisional application.
        <br /> 
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> &#x21DB; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >  Which will offer the following advantages:
           </span>{" "}  

        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
         Secures first priority date.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
        12 month window time to filed complete specification/Non-provisional patent application.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          Low cost.
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
        You have also 12 months to file PCT Application.
        <br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}> &#10687; </span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }} > 
          Why opt for Matic Research for Patent Filling...?
        </span>
        <br />
        At <span style={{ fontWeight: "bold", color: "#ff0d76" }} > Matic Research </span>, our team of
        experts is here to help you establish the most robust patent
        documentation and guide you through the intricate process of filing a
        patent in India and worldwide. They possess an in-depth understanding of
        all the procedural requirements of the patent office. Our patent
        professionals and advisors specialize in PCT Application and
        international filings. We facilitate filings in nearly all jurisdictions
        through our collaboration with patent attorneys in various countries.
        <br /> 
        <br />
      </p>
    </div>
  );
};
