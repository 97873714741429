export const ValidationSearch = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        > 
        < br /> 
    
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Validity Search </span>{" "} 
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >At Matic Research, 
          </span>{" "}
          we provide Patent Validation Searches tailored for patent assignees and owners. Our aim is to 
          bolster confidence in the validity and enforceability of granted patents. This thorough search 
          encompasses both Patent and Non-Patent Literature, meticulously distinguishing the invention from 
          existing Prior Art and showcasing its novelty in the market. Our primary objective is to underscore 
          strengths and authenticate the granted patent's validity. These searches play a crucial role in 
          IP Due Diligence processes.
          <br />
          <br />
          We recognize the importance of Patent Validity searches, particularly for organizations engaged 
          in mergers or acquisitions where patents are a substantial asset. These searches serve to confirm
           patent validity and mitigate future risks of invalidation. Whether conducted before or after a 
           patent is granted, they provide essential assurance. Additionally, individuals who have recently 
           filed a patent application can benefit from a validity search to evaluate its strength,
            ensuring novelty and fortifying against potential invalidation concerns down the line.
          <br />
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >How we can help you in your Patent Validation Search…?
          </span>{" "}
          <br />  
          Our specialized team is equipped to manage diverse Patent validations, accessing extensive databases 
          to assess validity and explore most relevant prior art. Our experts possess technical domain
           proficiency and understand CPC 52, 53 & 54, as well as USC 101, 102, and 103. 
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          > Why you should choose Matic Research for Patent Validation Search…? 
          </span>{" "}
          <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714; </span>{" "} 
            Validation Searches demand a thorough examination of all patent and non-patent literature released before the priority date of the specified patent. 
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;  Matic Research </span>{" "} 
            validation search reports offer in-depth analyses of claim and their enablement, a summary of the search findings, a matrix outlining
            of the identified prior art references, and a detailed assessment of pertinent classifications. 
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714;  </span>{" "} 
            We conduct classification searches using various systems such as CPC Classifications, US Classifications, and IPC Classifications. 
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714; </span>{" "} 
            Proficiency in utilizing diverse patent and non-patent databases. 
            <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714;  </span>{" "} 
            Scrutiny of the file wrapper or prosecution history associated with the target/subject patent.  
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714;  </span>{" "} 
           Charting the claims of the target/subject patent alongside relevant references outlined in the search report.
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>   &#x2714; </span>{" "} 
            Identifying and providing commentary on sections explaining the relevance of references in the search report. 
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714; Matic Research </span>{" "} 
            validation search reports are comprehensive and self-explanatory, comprising detailed analyses of prior art, web links for complete specifications, visual representations, 
            analyses of relevant references, complete bibliographic data, and their legal status. 
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714;  </span>{" "} 
            Inclusion of copies of patent and non-patent references mentioned in the patent examiner search report.
          <br /> 

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714;  </span>{" "} 
            Multiple quality assurance conducted before delivering the final search report. 
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}>  &#x2714;  </span>{" "} 
            Delivering exceptional quality at a reasonable cost.  
          <br /> 
          <br />
          <br />
          <br />
          <br />
          
        </p>
      </div>
    );
  };
  