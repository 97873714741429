export const LandscapeAnalysis = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          > Landscape Analysis </span>{" "} 
         <br />
         <br /> 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >At Matic Research,  
          </span>{" "}
          we provide advanced IP intelligence and patent landscape search services to keep our clients at the
          forefront of innovation. Our landscape services merge the powerful AI insights of our proprietary 
          tools with the expertise of our seasoned engineers and scientists, delivering unparalleled knowledge
          and actionable intelligence. Clients have utilized our tailored landscape solutions for innovation 
          intelligence; patent scouting, strategic R&D planning, freedom to operate analyses,
          identifying technology gaps, and exploring in-licensing or out-licensing opportunities within 
          comprehensive market analyses. 
          <br />
          <br />
          We offer comprehensive services including patent prosecution and litigation searches, 
          infringement analysis, patent licensing support, patent landscape and white space analysis,
          competitor analysis, as well as R&D search and analysis support. Our expertise spans across
          virtually every industry, ensuring that our clients receive thorough and tailored intellectual 
          property solutions.
           <br />
           <br />
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "26px" }}
          >How We Do it...?
          </span>{" "}
          <br /> 
  
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> Phase 1: </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Understanding Client Needs 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "}   </span>{" "}
          Identify and comprehend the specific requirements of the client.
          <br /> 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> Phase 2:  </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Conduct Preliminary Technical Study 
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} </span>{" "}
          Perform an initial study of the technical aspects related to the client's topic.
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> Phase 3: </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Define Landscape Search Scope 	
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} </span>{" "}
          Outline the scope of the landscape search to ensure comprehensive coverage.
          < br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> Phase 4: </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Develop Patent Search Strategy 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} </span>{" "}
          Formulate a strategy to effectively identify relevant patent documents.
          < br/> 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> Phase 5: </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Categorize Collections
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} </span>{" "}
          Organize the gathered data into various relevant categories.
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> Phase 6: </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> Generate Detailed Report
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}> &#x2714;</span>{" "} </span>{" "}
          Compile a comprehensive report featuring statistical analyses and insights. 
          < br />
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
         
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Why Choose Matic Research for Patent Landscape Search...?
          </span>{" "}
          <br />
         
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}
          >	&#x21DB; </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}
          >	Expert Technical Understanding and In-Depth Analysis: </span>{" "} 
          
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >At Matic Research, 
          </span>{" "} 
          
          we begin the creation of a patent landscape report with a thorough understanding of the technology
           involved. Our team dives deep into the technical aspects, utilizing a wide range of resources such 
           as press releases, blogs, and technical articles to gain comprehensive insights. For instance,
            if we're developing a landscape report on "Flexible Electronic Displays," we first ensure we
             understand the mechanics, differentiation from other display technologies, and the benefits to end users. This meticulous research lays the foundation for an outstanding landscape report by equipping us with the necessary knowledge to provide accurate and valuable insights.
          <br />
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "21px" }}
          >	&#x21DB; </span>{" "} 
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}
          >	Tailored Client-Centric Approach: </span>{" "} 

          < br /> 
          We understand that each client has unique needs and objectives for their landscape reports.
          Whether clients are interested in patent activities in specific regions, the portfolios of 
          companies patenting in a particular domain, investment opportunities, competitor activities,
          or the chronological development of a technology, we tailor our reports accordingly. 
          By closely aligning with our clients' goals, we ensure that our landscape reports deliver
          the precise information they need to make informed decisions. Our personalized approach 
          guarantees that the insights we provide are relevant, actionable, and aligned with each
          client's strategic objectives.

          



           
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  