export const IPMonitorings = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br />
        <br /> 
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          > Intellectual Property Monitoring </span>{" "}
        <br /> 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > At Matic Research, </span>{" "} Intellectual Property (IP) Monitoring service 
          involves the systematic tracking and analysis of intellectual property assets to detect
           potential infringements, ensure compliance, and safeguard IP rights. This comprehensive 
           process entails monitoring competitors, market trends, and legal developments to protect patents,
           trademarks, copyrights, and other IP assets effectively.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Key Aspects of IP Monitoring: </span>{" "} 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
          Patent Monitoring:  </span>{" "}
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}>
             ✔  </span>{" "} Regularly scrutinize patent databases to identify new filings or publications that may infringe on existing patents.
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> 
            ✔  </span>{" "} Monitor the progress of pending patent applications to anticipate potential challenges.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
         Trademark Monitoring: </span>{" "} 
         <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Monitor trademark registries and marketplaces to detect unauthorized use of trademarks.
         <br /><span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Identify conflicts arising from new trademark applications and take appropriate actions.
         <br /> 
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
         Copyright Monitoring: </span>{" "} 
         <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Track the usage of copyrighted materials across platforms to detect unauthorized reproductions or distributions. 
         <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "}Utilize digital tools for internet scanning to identify instances of copyright violations..
         <br /> 
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
         Competitor Monitoring: </span>{" "}
          <br/>  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Stay vigilant regarding competitors' IP activities to predict their strategic direction. 
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "}Analyze competitors' recent filings and market entries to uncover potential infringements.
          <br /> 
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
         Market Surveillance: </span>{" "} 
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "}Monitor both online and offline marketplaces for counterfeit products or services that violate IP rights.
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Employ automated tools and technologies for real-time detection of IP violations. 
          < br />
          < br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
        Legal and Regulatory Updates: </span>{" "} 
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Stay abreast of changes in IP laws and regulations to adapt IP strategies accordingly.
         <br />  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Monitor legal proceedings and rulings to understand their implications on IP protection..
          <br />
          <br /> 
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#x21DB; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
        Enforcement Actions: </span>{" "} 
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Initiate legal proceedings against infringers to safeguard IP rights.
          <br /> <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Collaborate with law enforcement agencies and legal experts to address IP violations effectively.
          <br />
          <br /> 
          By implementing robust IP Monitoring practices, <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> 
          Matic Research </span>{" "} empowers businesses to proactively safeguard their intellectual property,
          mitigate infringement risks, and retain a competitive advantage in the market.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#10687;</span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}> 
          At Matic Research, we follow the following steps in conducting Intellectual Property (IP) monitoring:  </span>{" "} 
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Define Objectives: </span>{" "}
          <br />    
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Clearly delineate the goals and objectives of the IP monitoring process.
           Specify the aspects of intellectual property requiring monitoring and protection
            <br /> 
            <br /> 
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
            <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Identify IP Assets:  </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Compile a comprehensive inventory of 
            intellectual property assets owned or managed by your organization. This encompasses patents, 
            trademarks, copyrights, trade secrets, and other proprietary information.
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Select Monitoring Tools:  </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "}  Choose suitable tools and technologies for IP monitoring. Options may include specialized 
            software for patent and trademark databases, online monitoring platforms, and legal research resources.
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
          Set Up Monitoring Parameters:  </span>{" "} 
            <br />
            
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Establish specific parameters for monitoring each type of intellectual property. 
            Define keywords, search criteria, and sources to track potential infringements or unauthorized usage.
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Establish Monitoring Schedule:  </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Develop a regular monitoring schedule to maintain continuous surveillance of intellectual property assets. 
            Determine the frequency of monitoring activities based on asset nature and risk levels.
         <br /> 
         <br />
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Conduct Monitoring Activities:  </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Execute the monitoring plan by systematically searching and reviewing
             pertinent sources for indications of potential infringements or misuse of intellectual property.
         <br />
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Analyze Results: </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Evaluate findings from monitoring endeavors to detect any signs of infringement or unauthorized use.
             Assess the severity of identified issues and prioritize them for further action.
         <br />
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Take Action: </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Respond appropriately to address identified infringements or violations of intellectual
             property rights. This may involve issuing cease and desist letters, initiating legal 
             proceedings, or pursuing alternative enforcement measures.
         <br />
         <br /> 

         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
           Document and Record: </span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Maintain meticulous records of all monitoring activities, findings, and actions taken.
             Document any correspondence or legal proceedings associated with intellectual property enforcement.
         <br />
         <br /> 
         <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          >  &#x21DB; </span>{" "}
         <span style={{ fontWeight: "bold", color: "#fff", fontSize: "20px" }}> 
            Review and Update:</span>{" "} 
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} Regularly review and update the IP monitoring process to accommodate changes in intellectual property laws, regulations, and market dynamics. 
            Continuously refine monitoring parameters and strategies to optimize effectiveness.
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} By adhering to these steps, organizations can effectively conduct Intellectual Property (IP) Monitoring to safeguard their valuable intellectual assets and mitigate the risk of infringement or misuse.
         <br />
         <br /> 


           
          <br /> 
          <br /> 
          
        </p>
      </div>
    );
  };
  