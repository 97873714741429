export const PatMonitor= () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Patent Monitoring </span>{" "} 
         <br /> 
         <br />
         When it comes to intellectual property (IP), our Patent Monitoring service at, 
         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > Matic Research 
           </span>{" "} 
           involves thorough searches of patent databases and periodicals. We meticulously track recently submitted or awarded patents 
           relevant to specific technologies, businesses, or sectors, providing valuable insights to our clients. 
          <br />
          <br />
          Keeping an eye on patents is essential to maintaining their market value. 
          Our expertise at 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > Matic Research 
           </span>{" "} 
          lies in our ability to aggressively prevent the registration 
          of inventions that are exact replicas of your concept or invention. You can rely on us to 
          manage your IP operations since we offer comprehensive,
          individualized patent Monitoring services that are designed to keep you competitive.
          <br />
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
           <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           > What makes Matic Research a preferred choice for your patent monitoring service…?
           </span>{" "} 
           <br />
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research,
           </span>{" "} 
           we offer tailored Intellectual Property Monitoring services to suit your requirements. 
           Whether you prefer periodic, annual, or monthly monitoring, we have you covered. 
           Our range of Patent Monitoring services is designed to be flexible and customizable, ensuring 
           we meet your specific needs effectively. 
           <br /> <br /> 
           We provide a patent monitoring service to notify you of any updates regarding the patent's current status. This contains information about your particular needs, including your legal status, current assignee, maintenance fee status, patent lifespan, security interest, lawsuit check, licensing check, and any other information as per your specific requirements.

          
           
          <br />
          <br />
        </p>
      </div>
    );
  };
  