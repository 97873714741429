import React, { useState } from "react";
import screenshot from "../../assets/MarketResearch.jpg";
import {
  buttonStyle,
  containerStyle,
  containerHeadingStyle,
  containerParaStyle,
} from "../../Styles/styles";
import { useNavigate } from "react-router-dom";
import "./MarketResearchHeading.css"; // Import the CSS for animations

export const MarketResearchHeading = ({ setSelectedComponent }) => {
  const Navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonArr = [
    {
      name: "Competitive Analysis",
      component: "CompetitiveAnalysis",
      link: "Competitive Analysis",
    },
    {
      name: "Landscape Analysis",
      component: "LandscapeAnalysis",
      link: "Landscape Analysis",
    },
    {
      name: "Product Line Comparison",
      component: "ProductLinecomparison",
      link: "Product Line Comparison",
    },
    {
      name: "State of the Art",
      component: "StateoftheArt",
      link: "State of Art Analysis",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        background: "#00061A",
        height: "100%",
        paddingLeft: "50px",
        paddingRight: "10px",
        marginBottom: "0px",
      }}
    >
      <div
        className="col-1 slide-left"
        style={{ width: "50%", paddingTop: "50px" }}
      >
        <h1
          style={{
            color: "#fff",
            fontSize: "55px",
            lineHeight: "1.2",
            marginBottom: "0px",
            textAlign: "left",
            background: "#00061A",
            marginTop: "0px",
          }}
        >
          Market Research
        </h1>
        <p
          style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "20px",
          }}
        >
          We analyze industry trends, competitor activity, and consumer
          preferences to guide strategic decisions for optimal intellectual
          property protection and commercialization.
        </p>
        {buttonArr.map((item, index) => (
          <button
            key={index}
            style={{
              border: "2px solid #ff0d76",
              color: hoveredButton === index ? "#ff0d76" : "#fff",
              background: "#00061a",
              borderRadius: "30px",
              borderWidth: "3px",
              margin: "10px",
              padding: "20px",
              cursor: "pointer",
              width: "auto",
              fontSize: "18px",
              fontWeight: "600",
            }}
            onMouseEnter={() => setHoveredButton(index)}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => {
              setSelectedComponent(item.component);
              if (item.link) Navigate(item.link);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div
        className="col-1 slide-right"
        style={{ width: "50%", paddingRight: "40px" }}
      >
        <img
          style={{
            height: "500px",
            width: "100%",
            marginTop: "50px",
          }}
          src={screenshot}
          alt="Patent Prosecution Screenshot"
        />
      </div>
    </div>
  );
};
