export const ReverseEngineering = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
          <br /> 
          <br />
          <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Reverse Engineering</span>{" "} 
         <br /> 
         <br />

         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  At Matic Research, 
           </span>{" "} 
           we offer a cutting-edge Reverse Engineering service inspired by the curiosity we all had as children - disassembling toys to understand how they worked. Today, this same
           approach is invaluable for uncovering whether a product or service incorporates proprietary technology.
         <br />
         <br />
          
           Reverse engineering involves meticulously dissecting the fundamental components of technology. Objects are taken apart, and their various elements—code, design, structure—are scrutinized. The objective is to gather insights into the components utilized in creating the object. This method is applicable across software, 
           hardware, and even genetic material from plants and animals.
           <br />
           <br />
           With our Reverse Engineering service, we provide comprehensive analysis and insights, empowering our 
           clients to gain a deep understanding of proprietary technologies and make informed decisions.
           <br />
           <br />
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >  At Matic Research, 
           </span>{" "} 
           our team of reverse engineering specialists boasts extensive expertise
          in a multitude of analysis techniques.
       
         <br />
         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span>{" "} In order to develop schematics and related documents, circuit analysis requires comprehending how devices work and dissecting them at every level, from transistors to the connections and parts needed.
        <br />
        <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span>{" "} Process analysis looks into patented semiconductor, optical, and MEM techniques, along with packaging and layout components, to find out what parts and how they are employed in devices.
        <br />
        <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >  <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
          </span>{" "} Finding the collaborative use of devices through sensing, probing, data collection, or analyzing signals and software within or between chips is the task of system analysis.

        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >  What makes Matic Research a preferred choice for your Reverse Engineering...? 
           </span>{" "} 
           <br />
        
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "} 	Reverse engineering specialists of the highest caliber, possessing a wealth of knowledge in important technical fields such as software, electronics, networking, and communication.
           <br />
           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "} As well as a wide range of consumer electronics products including wearables, tablets, smart home appliances, cameras, and so forth, we have in-depth knowledge of a wide range of devices, including phones, routers, hard drives, LCD/LED/OLED displays, RFID tags, batteries, and coating materials.
            <br />
            <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }} > ✔ </span>{" "} We provide adjustments and Customized Report according to the particular needs of the client and the chosen field of interest.


          <br />
          <br />
        
        </p>
      </div>
    );
  };
  