import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/HeaderOne";

export const Navbar = () => {
  const Navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState(null);

  const menuItems = [
    {
      name: "Patent Prosecution",
      path: "/patent/patent-prosecution/",
      subtopics: [
        "Patent Filing",
        "Patent Drafting",
        "Patentability Search",
        "Patent Search (Design)",
        "Office Action Responses",
        "Directed Patent Prosecution",
      ],
    },
    {
      name: "Patent Litigation",
      path: "/patent/patent-litigation/",
      subtopics: [
        "Claim Charts",
        "Validity Search",
        "Invalidity Search",
        "Inter-Party Review",
        "Pre/Post Grant Opposition",
      ],
    },
    {
      name: "Commercialization Property",
      path: "/patent/commercialization-property/",
      subtopics: [
        "FTO Search",
        "Evidence of Use",
        "Inventor Watch",
        "Licensing Support",
        "Patent Monitoring",
        "Infringement Search",
        "Patent Due Diligence",
        "Reverse Engineering",
        "Patent Portfolio Ranking",
      ],
    },
    {
      name: "Market Research",
      path: "/patent/market-research/",
      subtopics: [
        "Landscape Analysis",
        "State of Art Analysis",
        "Competitive Analysis",
        "Product Line Comparison",
      ],
    },
    {
      name: "R&D Support",
      path: "/patent/rd-support/",
      subtopics: [
        "Data Collection and Analysis",
        "Prototype Development",
        "Technical Writing",
        "Intellectual Property (IP) Management",
      ],
    },
    {
      name: "Competitive Watch",
      path: "/patent/competitive-watch/",
      subtopics: [
        "Product/Service tracking",
        "Intellectual Property Monitoring",
        "Competitor Analysis",
      ],
    },
  ];

  return (
    <div>
      <Header />
      <nav
        style={{
          backgroundColor: "#1a1f30",
          padding: "10px",
        }}
      >
        <ul
          style={{
            listStyleType: "none",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            padding: "0",
          }}
        >
          {menuItems.map((item, index) => {
            const { path } = item;

            return (
              <li
                key={index}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                style={{
                  position: "relative",
                }}
              >
                <Link
                  to={item.path}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "8px 12px",
                    fontSize: "18px",
                    display: "inline-block",
                    // transition: "background-color 0.3s",
                    background: "#1a1f30",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "#f5e949")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "#fff")}
                >
                  {item.name}
                </Link>
                {/* Show subtopics on hover */}
                {hoveredItem === index && item.subtopics.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      left: "0",
                      backgroundColor: "#00061a",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      borderRadius: "5px",
                      zIndex: 1000,
                      width: "250px",
                      // overflowX: "scroll",
                    }}
                  >
                    {item.subtopics.map((subtopic, subIndex) => (
                      <div
                        key={subIndex}
                        style={{
                          // padding: "8px",
                          fontSize: "16px",
                          color: "#fff",
                          cursor: "pointer",
                          background: "#00061a",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.color = "#f5e949";
                          e.currentTarget.style.textDecoration = "underline";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.color = "#fff";
                          e.currentTarget.style.textDecoration = "none";
                        }}
                        onClick={() => Navigate(`${path}${subtopic}`)}
                      >
                        {subtopic}
                      </div>
                    ))}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
