export const PPortfolioRanking = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
       <br />
       <br /> 
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Patent Portfolio Ranking</span>{" "} 
         <br /> 
         <br />
         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           >At Matic Research, 
           </span>{" "} 
           , we understand that the value of your intellectual property assets may vary, regardless of quantity.
            What truly matters is the strategic positioning of patents within your portfolio. Our Patent Portfolio Ranking service offers a comprehensive analysis and system designed to evaluate the relevance of patents within your organization's business environment. By assessing various business opportunities, we help you identify the strengths and weaknesses of your patent portfolio, 
           enabling informed decisions to maximize its advantages. Trust 
    
         <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > Matic Research 
           </span>{" "} 
           for expert guidance in optimizing your patent assets.
           <br />
           <br />
           
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x21DB; </span>{" "} 
           <span  style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >  Here are the main purposes of the Patent Portfolio Ranking:
           </span>{" "} 
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
           Acquisition,
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            Licensing. 
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
           Valuation.
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> &#x2714;</span>{" "} 
            Sales.
        <br />
        <br />
        <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}>  &#10687;</span>{" "}
           <span style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
           >  What makes Matic Research a preferred choice for your Patent Portfolio Ranking…?
           </span>{" "} 
           <br />

           <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
           > At Matic Research, 
           </span>{" "}  our adept team provides invaluable insights into upcoming developments.
            By evaluating patents meticulously, we gain clarity on their value, potential monetization,
            and licensing opportunities. Utilizing a blend of qualitative and quantitative criteria,
            we ensure well-informed decisions to optimize returns for our clients.
            <br />
            <br />We produce a list of potential high-value patents using our unique patent-ranking system. 
            After that, our technical experts thoroughly examine the chosen patents to determine 
            which ones are the most valuable. In order to determine the best patents for licensing, 
            merger, acquisition, sale, and valuation, a variety of factors are taken into account 
            in this ranking study, including the number of family members, claim length,
             market application, enforceability, and validity.

        

          <br />
          <br />
        
        </p>
      </div>
    );
  };
  