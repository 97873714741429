import { useState } from "react";
import { useParams } from "react-router-dom";
import { CommercializationDescription } from "./Components/CommercializationDescription";
import { CommercializationSupport } from "./Components/CommercializationSupport";
import { EvOU } from "./Components/EvOU";
import { FTOSearch } from "./Components/FTOSearch";
import { InventorWatch } from "./Components/InventorWatch";
import { LicensingSupport } from "./Components/LicensingSupport";
import { PatMonitor } from "./Components/PatMonitor";
import { PPortfolioRanking } from "./Components/PPortfolioRanking";
import { ReverseEngineering } from "./Components/ReverseEngineering";
import { InfringementSearch } from "./Components/InfringementSearch";
import { PatentDueDiligence } from "./Components/PatentDueDiligence";
import FooterOne from "../../Footer/FooterOne";

export const CommercializatonProperty = () => {
  const subtopic = useParams();
  const value = subtopic[Object.keys(subtopic)[0]];
  const [, setSelectedComponent] = useState("CommercializationDescription");

  const renderComponent = () => {
    switch (value) {
      case "Evidence of Use":
        return <EvOU />;
      case "FTO Search":
        return <FTOSearch />;
      case "Inventor Watch":
        return <InventorWatch />;
      case "Licensing Support":
        return <LicensingSupport />;
      case "Patent Monitoring":
        return <PatMonitor />;
      case "Patent Portfolio Ranking":
        return <PPortfolioRanking />;
      case "Reverse Engineering":
        return <ReverseEngineering />;
      case "Infringement Search":
        return <InfringementSearch />;
      case "Patent Due Diligence":
        return <PatentDueDiligence />;
      default:
        return <CommercializationDescription />;
    }
  };

  return (
    <div>
      <CommercializationSupport setSelectedComponent={setSelectedComponent} />
      <div>{renderComponent()}</div>
      <FooterOne />
    </div>
  );
};
