import { useState } from "react";
import { CompetitiveWatchheading } from "./Components/CompetitiveWatchheading";
import { CompetitiveWatchDescription } from "./Components/CompetitiveWatchDescription";
import { CompetitorAnalysis } from "./Components/CompetitorAnalysis";
import { IPMonitorings } from "./Components/IPMonitorings";
import { ProductServiceTracking } from "./Components/ProductServiceTracking";
import { useParams } from "react-router-dom";
import FooterOne from "../../Footer/FooterOne";

export const CompetitiveWatch = () => {
  const subtopic = useParams();
  const value = subtopic[Object.keys(subtopic)[0]];
  const [, setSelectedComponent] = useState("CompetitiveWatchDescription");

  const renderComponent = () => {
    switch (value) {
      case "Competitive Watch Description":
        return <CompetitiveWatchDescription />;
      case "Competitor Analysis":
        return <CompetitorAnalysis />;
      case "Intellectual Property Monitoring":
        return <IPMonitorings />;
      case "Product/Service tracking":
        return <ProductServiceTracking />;
      default:
        return <CompetitiveWatchDescription />;
    }
  };

  return (
    <div>
      <CompetitiveWatchheading setSelectedComponent={setSelectedComponent} />
      <div>{renderComponent()}</div>
      <FooterOne />
    </div>
  );
};
