import { useState } from "react";
import { PatentLitigationHeading } from "./Components/PatentLitigationHeading";
import { PatentLitigationDescription } from "./Components/PatentLitigationDescription";
import { ClaimChart } from "./Components/ClaimChart";
import { InterPartesReviewService } from "./Components/InterPartesReviewService";
import { InvaliditySearch } from "./Components/InvaliditySearch";
import { PrePostGrantOpposition } from "./Components/PrePostGrantOpposition";
import { ValidationSearch } from "./Components/ValidationSearch";
import { useParams } from "react-router-dom";
import FooterOne from "../../Footer/FooterOne";

export const PatentLitigation = () => {
  const subtopic = useParams();
  const value = subtopic[Object.keys(subtopic)[0]];
  const [, setSelectedComponent] = useState("PatentLitigationDescription");

  const renderComponent = () => {
    switch (value) {
      case "Patent Litigation Description":
        return <PatentLitigationDescription />;
      case "Claim Charts":
        return <ClaimChart />;
      case "Inter-Party Review":
        return <InterPartesReviewService />;
      case "Invalidity Search":
        return <InvaliditySearch />;
      case "Pre/Post Grant Opposition":
        return <PrePostGrantOpposition />;
      case "Validity Search":
        return <ValidationSearch />;
      default:
        return <PatentLitigationDescription />;
    }
  };

  return (
    <div>
      <PatentLitigationHeading setSelectedComponent={setSelectedComponent} />

      <div>{renderComponent()}</div>
      <FooterOne />
    </div>
  );
};
