export const InterPartesReviewService = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        ><br /> 
        <span style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >   Inter-Party Review Service</span>{" "} 
         <br /> 
         <br />

          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >Matic Research 
          </span>{" "}
          proudly offers Inter Partes Review (IPR) services, a legal process sanctioned by the United States
           Patent and Trademark Office (USPTO). This mechanism empowers third parties to contest the validity 
           of one or more claims within a granted patent. Established by the America Invents Act (AIA) 
           in 2012, IPR represents a crucial post-grant review avenue. 
          <br />
          <br />
             <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > Matic Research </span>{" "} 
          offers Inter Partes Review (IPR) services, allowing anyone other than the patent owner to submit
           a petition for patent review. The decision to proceed to trial rests with the Patent Trial and 
           Appeal Board (PTAB), typically taking about six months from petition submission. Once scheduled,
            trials generally conclude within a year, barring exceptions. Reasons for filing an IPR are more
             limited compared to filing a Post-Grant Review (PGR). Petitioners may seek the cancellation of 
             patent claims based solely on prior art, encompassing patents or printed publications, and only 
             on grounds permissible under USC 102 or 103.
           <br />
           <br />
           <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "25px" }}
          > &#10687; </span>{" "} 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >What makes Matic Research the preferred choice for your Inter Partes Review Service…?
          </span>{" "}
          <br /> 
  
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} 
            Our experienced team is handling clients’ (Inter Partes Reviews) IPRs and is knowledgeable about the quick-paced processes as well as the legal and technological nuances involved.
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "} 
            Our IP specialists are highly adept at addressing a variety of patent validity issues in Inter Partes Review (IPR) proceedings before the Patent Trial and Appeal Board (PTAB).
          <br />
          <span style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}> ✔  </span>{" "}
           We consistently provide counsel to clients on both sides of legal disputes, whether they are challenging the validity of a patent or defending against such challenges.   
          <br />
          <br />  
          <br /> 
          <br /> 
          
          
        </p>
      </div>
    );
  };
  